
import React, { useState } from "react";
import { lightTheme, darkTheme, extendTheme } from '@strapi/design-system/themes';
import { branding, customized } from "../config";

export default function useTheme(){
    
    const getTheme = () => {        
        return JSON.parse(localStorage.getItem('theme'));
    };

    const [theme, setTheme] = useState(() => {
        return 'light';
    });
    // const [theme, setTheme] = useState(() => {
    //     return getTheme() || customized.defaultTheme;
    // });

    const saveTheme = theme => {
        localStorage.setItem('theme', JSON.stringify(theme));
        setTheme(theme);
    };

    const light = extendTheme(lightTheme, branding.themes.light);
    const dark = extendTheme(lightTheme, branding.themes.dark);

    return {
        setTheme: saveTheme,
        theme,
        lightTheme: light,
        darkTheme: dark,
        extendTheme        
    }

}