import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Loader } from '@strapi/design-system/Loader';

import useDeviceTypes from "../../hooks/useDeviceTypes";
import { apiAccounts, apiAuth } from "../../services";
import useAPI from "../../hooks/useAPI";
import useUser from "../../hooks/useUser";
import ScreenNames from "../../constants/ScreenNames";
import { apiCognito } from "../../services/api";

export default function VerifyScreen(props) {

    const history = useHistory(); 
    const { createAccount, fetchSubscriptions, fetchAccount } = useAPI();
    const { setAffiliate, setCampaign, setProduct, setAffiliateCampaign, setCart, setUser, setToken, setEntitlement, setRedirectUri, setCategory  } = useUser();
    const { isWidth } = useDeviceTypes();
    const [loading] = useState(true);
    const url = _.replace(window.location.href, '#', '?');
    const queryParams = new URLSearchParams(new URL(url).search);
    const token = queryParams.get('access_token');
    const product = queryParams.get('product');
    const entitlement = queryParams.get('entitlement');
    const redirect_uri = queryParams.get('auth_redirect_uri');
    const campaign = queryParams.get('campaign');
    const affiliate = queryParams.get('affiliate');
    const affiliate_campaign = queryParams.get('affiliate_campaign');
    const category = queryParams.get('category');

    const redirectFailure = () => {
        apiCognito.login();
    };

    const redirect = () => window.location.href = redirect_uri || process.env.REACT_APP_EXT_URL;

    function objectToUrlParams(obj) {
        return Object.entries(obj)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
    }

    const checkIfAccountExists = async( data ) => {

        //Save Params
        if (product !== 'null' && product !== null && product !== 'undefined' && product) setProduct(product);
        else setProduct('');
        if (redirect_uri !== 'null' && redirect_uri !== null && redirect_uri !== 'undefined' && redirect_uri) setRedirectUri(redirect_uri);
        else setRedirectUri('');
        if (entitlement !== 'null' && entitlement !== null && entitlement !== 'undefined' && entitlement) setEntitlement(entitlement);
        else setEntitlement('');
        if (affiliate !== 'null' && affiliate !== null && affiliate !== 'undefined' && affiliate) setAffiliate(_.toUpper(affiliate));
        else setAffiliate('');
        if (campaign !== 'null' && campaign !== null && campaign !== 'undefined' && campaign) setCampaign(campaign);
        else setCampaign('');
        if (affiliate_campaign !== 'null' && affiliate_campaign !== null && affiliate_campaign !== 'undefined' && affiliate_campaign) setAffiliateCampaign(affiliate_campaign);
        else setAffiliateCampaign('');
        if (category !== 'null' && category !== null && category !== 'undefined' && category) setCategory(category);
        else setCategory('');

        setCart([]);
        setUser({});

        try {
            let account = await fetchAccount( data.sub );
            console.log({ account });
            // If account does not exist, create one
            if (!account) {
                account = await createAccount( data );
            }
            setUser(account);

            // Check if user has an active subscription
            // Get subscriptions
            let subscriptions = await fetchSubscriptions();
            // Filter for "active" subscriptions
            subscriptions = _.filter(subscriptions, i => i.status === 'active');
            console.log({ subscriptions });
            if ( _.size(subscriptions) > 0 ) {
                // If user has an active subscription, redirect to "Billing" screen
                return history.push(ScreenNames.Billing);
            } else {
                let query = '';
                if(category) query = '?category=' + category;
                return history.push(ScreenNames.Subscriptions + query);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        (async() => {
            if(!token) apiCognito.login();
            try {
                // Verify token
                setToken(token);
                const { data } = await apiCognito.userInfo( token );
                // Check if accounts exists
                await checkIfAccountExists( data );
            } catch (error) {
                console.log(error);
                redirectFailure();
            }
        })();
    },[]);

    return (
        <Flex direction="column">
            {loading ? <Flex justifyContent='center' alignItems='center' direction="column" style={{ width: isWidth, height: '90vh' }} padding={8}>
                <Loader children='Verifying' />
            </Flex> : null}
        </Flex>
    )
}