export default {
    Home: '/',
    Subscriptions: '/subscriptions',
    Payment: '/subscriptions/payment',
    UpdatePayment: '/payment-methods/:id/update',
    UpdatePaymentManage: '/manage-subscription/update-payment',
    AddPayment: '/subscriptions/add-payment',
    AddPaymentManage: '/manage-subscription/add-payment',
    Billing: '/manage-subscription',
    Settings: '/settings',
    PurchaseConfirmation: '/subscriptions/purchase-confirmation',
    RetentionOffer: '/subscriptions/retention-offer',
    PurchaseSuccess: '/subscriptions/purchase-success',
    SignIn: '/sign-in',
    Verify: '/verify',
    CardFailure: '/card-failure',
    MobileSubscriptionRedirect: '/mobile-redirect-failure',
    CertCapture: '/tax-exemption',
    PayNow: '/invoices/:id/pay',
    OneTimePayment: '/otp',
    Error: '/error/:code',
}