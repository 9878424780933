import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from '@strapi/design-system/Typography';
import SubscriptionNowActiveBox from "../../components/SubscriptionNowActive";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import { StepperOne } from "../../components/custom/Steppers";
import ScreenNames from "../../constants/ScreenNames";

export default function PurchaseSuccessScreen(props) {

    const history = useHistory();
    const { token } = useUser();
    const { isWidth } = useDeviceTypes();

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column">
            {customized.stepperActive && <StepperOne step={customized.stepNumberByScreen.paymentSuccess} style={{ marginBottom: 50 }} />}
            <Typography variant={styleSettings.pageHeader}>{'Success!'}</Typography>
            <Flex justifyContent='center' alignItems='center' style={{ width: isWidth }} padding={8}>
                <SubscriptionNowActiveBox />
            </Flex>
            
        </Flex>
    )
}