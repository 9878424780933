import React, { useEffect, useState } from "react";
import clientConfig from "../clientConfig";
import _ from 'lodash';
import moment from "moment-timezone";
import { styleSettings } from "../config";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Accordion, AccordionToggle, AccordionContent } from '@strapi/design-system/Accordion';
import { Card, CardBody, CardSubtitle, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Box } from '@strapi/design-system/Box';
import { Alert } from '@strapi/design-system/Alert';
import { Link } from '@strapi/design-system/Link';
import { Checkbox } from '@strapi/design-system/Checkbox';
import { Select, Option } from '@strapi/design-system/Select';
import { asyncTimeout } from "../utils/helpers";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import Strings from "../constants/Strings";
import { currencyDisplay } from "../utils/helpers";
import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";
import ScreenNames from "../constants/ScreenNames";
import { CardBadge } from "@strapi/design-system";
import { Image } from "react-native";

import DDALogo from '../assets/images/payment-icons/check2.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';

export default function SubscriptionBox(props){

    const history = useHistory();
    const location = useLocation();
    const { cart, user, setSubscription: saveSubscription } = useUser();
    const { fetchPaymentMethods, fetchSubscriptions, createSubscription, updateSubscription } = useAPI();
    const queryParams = new URLSearchParams(window.location.search);
    const promo = queryParams.get('campaign');
    const [specialBillPlan, setSpecialBillPlan] = useState({});
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: '' });
    const [dryRunIssue, setDryRunIssue] = useState(false);
    const [dryRunIssueMessage, setDryRunIssueMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [agreeToSecondTerms, setAgreeToSecondTerms] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);
    const [showSecondTermsError, setShowSecondTermsError] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState(false);
    const [onRetentionOffer, setOnRetentionOffer] = useState(false);

    const [subscription, setSubscription] = useState({});
    const [subscriptions, setSubscriptions] = useState({});
    const [subscriptionDetails, setSubscriptionDetails] = useState({});
    const [dryRunSubscription, setDryRunSubscription] = useState({});
    const [currentSubscription, setCurrentSubscription] = useState({});

    const [loadingError, setLoadingError] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const setDefaultPaymentMethod = (paymentMethods) => {
        const defaultCard = _.find(paymentMethods, i => ((i.primary === true)));
        if(defaultCard) {
            setSelectedPaymentMethod(defaultCard?.id);
            return defaultCard?.id;
        } else {
            setSelectedPaymentMethod(paymentMethods[0]?.id);
            return paymentMethods[0]?.id;
        }
    }

    const submit = async() => {
        if(!selectedPaymentMethod) {
            setSelectedPaymentMethodError(true);
            return false;
        }
        setSelectedPaymentMethodError(false);

        if(!agreeToTerms) {
            setShowTermsError(true);
            return false;
        }
        setShowTermsError(false);

        if(!clientConfig.customized.hideSecondTerms && !agreeToSecondTerms) {
            setShowSecondTermsError(true);
            return false;
        }
        
        try {
            setLoading(true);
            const payload = {
                ...subscription,
                paymentMethodIds: [
                    selectedPaymentMethod
                ]
            }

            // Create the subscription
            let res = {};
            if(currentSubscription.id) {
                res = await updateSubscription(currentSubscription.id, payload);
            } else {
                res = await createSubscription(payload);
            }
            
            if(!res) throw new Error('Failed to create subscription');
            saveSubscription(res);
            return history.push(ScreenNames.PurchaseSuccess, { subscription: res });
            
        } catch (error) {
            console.log({ error });
            // Handle errors here
            setShowAlert({ variant: 'danger', active: true, body: _.toString(error) });
            await asyncTimeout(10000);
            setShowAlert(prev => { return {...prev, active: false}});
        } finally {
            setLoading(false);
        }
    }

    const getLogo = (paymentMethod) => {

        // console.log('getLogo - Input:', { paymentMethod });
        
        const brand = _.toLower(_.get(paymentMethod, 'brand'));
        
        if (_.isUndefined(brand)) return DefaultLogo;
    
        // console.log('getLogo - "brand":', { paymentMethod, brand });
            
        switch (brand) {
          case 'amex':
            return AmexLogo;
          case 'discover':
            return DiscoverLogo;
          case 'mastercard':
            return MastercardLogo;
          case 'visa':
            return VisaLogo;
          case 'electroniccheck':
            return DDALogo;
          default:
            return DefaultLogo;
        }
    };

    const Frequency = {
        daily: 'daily',
        annually: 'annually',
        monthly: 'monthly',
        once: 'once',
        quarterly: 'quarterly',
        semiannual: 'semiannual',
    }    

    const calculateNextBillDate = (frequency) => {
        switch (frequency) {
            case Frequency.daily:
                return moment().add(1, 'day').format('MMMM DD, YYYY')
            case Frequency.monthly:
                return moment().add(1, 'month').format('MMMM DD, YYYY')
            case Frequency.annually:
                return moment().add(1, 'year').format('MMMM DD, YYYY')
            case Frequency.once:
                return moment().format('MMMM DD, YYYY')
            case Frequency.quarterly:
                return moment().add(3, 'month').format('MMMM DD, YYYY')
            case Frequency.semiannual:
                return moment().add(6, 'month').format('MMMM DD, YYYY')
            default:
                return moment().format('MMMM DD, YYYY')
        }
    }

    // Determine friendly frequency text
    const getFrequencyPerText = (frequency) => {
        switch (frequency) {
            case Frequency.daily:
                return 'per day'
            case Frequency.monthly:
                return 'per month'
            case Frequency.annually:
                return 'per year'
            case Frequency.once:
                return 'once'
            case Frequency.quarterly:
                return 'per quarter'
            case Frequency.semiannual:
                return 'semiannually'
            default:
                return 'per month'
        }
    }

    // Calculate the total from products amount
    const calculateTotal = (products) => {
        let total = 0;
        _.map(products, i => {
            total += i.amount;
        });
        return total;
    }

    useEffect(() => {
        (async() => {
            //Check if item in cart and subscription box not on manage subscription screen, return to subscriptions
            if(!cart[0]?.package && (location.pathname !== ScreenNames.Billing) && (location.pathname !== ScreenNames.RetentionOffer)) return history.push(ScreenNames.Subscriptions);
            console.log(cart[0]?.package);            
            const product = cart[0]?.package;
            // Get payment methods
            const paymentMethods = await fetchPaymentMethods();
            setPaymentMethods(paymentMethods);
            const defaultPaymentMethodId = setDefaultPaymentMethod(paymentMethods);

            // Get subscriptions
            let subscriptions = await fetchSubscriptions();
            // Filter for "active" subscriptions
            subscriptions = _.filter(subscriptions, i => i.status === 'active');

            if ( _.size(subscriptions) > 0 &&  _.get(product, 'id')) {
                let _subscription = subscriptions[0];
                setCurrentSubscription(_subscription);
                console.log({ _subscription });
                setSubscription({
                    collectionMethod: "AUTO",
                    customer: {
                        id: user.id,
                    },
                    // merchantSubscriptionId: "35653498",
                    start: moment().toISOString(),
                    total: calculateTotal(product.prices) * 100,
                    paymentMethodIds: [
                        defaultPaymentMethodId
                    ],
                    products: [
                        {
                            currency: {
                                name: _.get(product, 'prices.0.currency.name'),
                                symbol: _.get(product, 'prices.0.currency.symbol'),
                            },
                            id: _.get(product, 'id'),
                            merchantId: "134713457",
                            merchantProductId: _.get(product, 'merchantProductId',  ''),
                            sku: _.get(product, 'sku'),
                            type: _.get(product, 'type'),
                            description:_.get(product, 'description'),
                            metadata: _.get(product, 'metadata'),
                            frequency: _.get(product, 'prices.0.frequency'),
                            amount: _.get(product, 'prices.0.amount'),
                        }
                    ]
                });

                setSubscriptionDetails({
                    nextBillDate: calculateNextBillDate(_.get(product, 'prices.0.frequency')),
                    total: calculateTotal(product.prices),
                    description: _.get(product, 'description'),
                });
            }
            else if ( _.size(subscriptions) > 0 ) {
                let _subscription = subscriptions[0];
                setCurrentSubscription(_subscription);
                console.log({ _subscription });
                setSubscription({
                    ..._subscription
                });

                setSubscriptionDetails({
                    nextBillDate: moment(_.get(_subscription, 'nextBill.date')).format('MMMM DD, YYYY'),
                    total: _.get(_subscription, 'total'),
                    description: _.get(_subscription, 'products.0.description', ''),
                });
            }
            else {
                setSubscription({
                    collectionMethod: "AUTO",
                    customer: {
                        id: user.id,
                    },
                    // merchantSubscriptionId: "35653498",
                    start: moment().toISOString(),
                    total: calculateTotal(product.prices) * 100,
                    paymentMethodIds: [
                        defaultPaymentMethodId
                    ],
                    products: [
                        {
                            currency: {
                                name: _.get(product, 'prices.0.currency.name'),
                                symbol: _.get(product, 'prices.0.currency.symbol'),
                            },
                            id: _.get(product, 'id'),
                            merchantId: "134713457",
                            merchantProductId: _.get(product, 'merchantProductId',  ''),
                            sku: _.get(product, 'sku'),
                            type: _.get(product, 'type'),
                            description:_.get(product, 'description'),
                            metadata: _.get(product, 'metadata'),
                            frequency: _.get(product, 'prices.0.frequency'),
                            amount: _.get(product, 'prices.0.amount'),
                        }
                    ]
                });

                setSubscriptionDetails({
                    nextBillDate: calculateNextBillDate(_.get(product, 'prices.0.frequency')),
                    total: calculateTotal(product.prices),
                    description: _.get(product, 'description'),
                });
            }

            setLoading(false);
        })();
    },[promo]);
    

    return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Card>
                
                <CardBody>
                    <CardContent paddingTop={2} paddingBottom={2}>
                        <CardTitle variant={styleSettings.contentBox.headerVariant}>{props.title || Strings.subscription}</CardTitle>
                        {props.summary && currentSubscription.id && <CardTitle style={{ fontSize: 12, marginTop: 5 }} variant='pi' textColor="primary500">{'You are updating your current subscription.'}</CardTitle>}
                    </CardContent>
                </CardBody>
                <Divider />
                {loading &&
                    <>
                        <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                            <Loader small children={`${Strings.loading} ${Strings.subscription}`} />
                            <Typography children={`${Strings.loading} ${Strings.subscription}`} style={{ marginTop: 10 }} />
                        </Flex>
                    </>
                }

                {!loading && !dryRunIssue && subscriptionDetails?.total ?
                    <>  
                        {props.summary && <CardBody paddingTop={2}>                            
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.company}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.name}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.address}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.email}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.phone}</CardSubtitle>
                            </CardContent>
                        </CardBody>}
                        <CardBody paddingTop={!props.summary ? 2 : 0}>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{!props?.summary ? Strings.nextBillDate : Strings.firstBillDate}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{subscriptionDetails?.nextBillDate}</CardSubtitle>
                            </CardContent>
                            {currentSubscription?.status === 'active' && !props.summary && <CardBadge backgroundColor='success500' textColor='neutral100'>{currentSubscription.status}</CardBadge>}
                            {currentSubscription?.status !== 'active' && !props.summary && <CardBadge backgroundColor='danger500' textColor='neutral100'>{currentSubscription.status}</CardBadge>}
                        </CardBody>
                        <CardBody>
                            {props.summary ? <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.price}</CardTitle>
                                {
                                    dryRunSubscription?.items?.data[0]?.campaign_id ? (
                                        <Flex direction='column' style={{ alignItems: 'flex-start'}}>
                                                <CardSubtitle variant="pi" style={{ marginTop: 0, wordBreak: 'keep-all', textDecoration: 'line-through', marginRight: 10, textAlign: 'flex-start' }}>{`${currencyDisplay(dryRunSubscription?.items?.data[0]?.product?.prices?.data[0]?.amount)}`}</CardSubtitle> 

                                                {!onRetentionOffer && <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                                    {`${currencyDisplay(subscriptionDetails?.total)}`}
                                                </CardSubtitle>}
                                            <Typography
                                            style={{ wordBreak: 'break-word', marginTop: 5, fontSize: 13, fontStyle:'italic', textAlign: 'center' }}
                                            textColor={'primary500'}
                                            variant="pi">
                                                { dryRunSubscription?.campaign_response?.flat_amount_discounts?.data && 
                                                    `$${dryRunSubscription?.campaign_response?.flat_amount_discounts?.data[0]?.amount} off your 
                                                    ${((dryRunSubscription?.campaign_response?.number_of_periods === 1) || !dryRunSubscription?.campaign_response?.number_of_periods) ? `first ${subscription.term}` : `first ${dryRunSubscription?.campaign_response?.number_of_periods} ${subscription.term}s`}. Full price when promotion ends.` 
                                                }

                                                { dryRunSubscription?.campaign_response?.percentage_discount?.data && 
                                                    `${dryRunSubscription?.campaign_response?.percentage_discount?.data[0]?.amount}% off your 
                                                    ${((dryRunSubscription?.campaign_response?.number_of_periods === 1) || !dryRunSubscription?.campaign_response?.number_of_periods) ? `first ${subscription.term}` : `first ${dryRunSubscription?.campaign_response?.number_of_periods} ${subscription.term}s`}. Full price when promotion ends.` 
                                                }
                                            </Typography>
                                        </Flex>
                                    ) : (
                                        <>
                                            {!onRetentionOffer && <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{currencyDisplay(subscriptionDetails?.total)} {getFrequencyPerText(subscription?.frequency)} + tax</CardSubtitle>}
                                        </>
                                    )
                                }
                                
                            </CardContent> : <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{'Next Bill Amount'}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{currencyDisplay(subscriptionDetails?.total)} {getFrequencyPerText(subscription?.frequency)} + tax</CardSubtitle>
                            </CardContent>}
                        </CardBody>
                        <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.product}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{subscriptionDetails?.description}</CardSubtitle>
                            </CardContent>
                        </CardBody>
                        {/* <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{'Tax Exemption'}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                    {   
                                        !taxExemptionLoading
                                        ? <Link
                                            onClick={onTaxExemption}
                                            children={'Are you tax exempt? Click here to submit your documentation.'}
                                            style={{
                                                flexWrap: 'wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        />
                                        : <Typography style={{ marginTop: 8 }}>Loading...</Typography>
                                    }
                                </CardSubtitle>
                            </CardContent>
                        </CardBody> */}
                        {_.size(subscription.addOns) > 0 && <CardBody>
                            <CardContent style={{ width: '100%'}}>
                                <Accordion expanded={expanded} toggle={() => setExpanded(s => !s)} id="acc-1" size="S" variant="secondary">
                                    <AccordionToggle title={`${Strings.addOns} (${_.size(subscription?.addOns)})`} />
                                    <AccordionContent padding={3}>
                                        {_.map(subscription?.addOns, i => <Box key={i.id} >
                                            <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{i.product}</CardSubtitle>
                                            <CardSubtitle style={{ marginBottom: 10}} variant={'sigma'}>{currencyDisplay(i.prices.price)}</CardSubtitle>
                                        </Box>)}
                                    </AccordionContent>
                                </Accordion>
                            </CardContent>
                        </CardBody>}
                        {props.summary && <CardBody>                            
                            <Flex alignItems="flex-start" direction='column' style={{ width: '100%' }}>
                            <CardContent style={{ width: '100%' }}>
                                <Card>
                                    <CardContent padding={3}>
                                        <Select 
                                        label="Select a payment method"
                                        clearLabel="Clear the payment method"
                                        placeholder="Select a payment method"
                                        onClear={() => setSelectedPaymentMethod('')}
                                        onChange={(e) => setSelectedPaymentMethod(e)}
                                        value={selectedPaymentMethod}
                                        error={selectedPaymentMethodError ? 'Please provide a payment method' : ''}
                                        style={{ paddingBottom: 30 }}>
                                            {_.map(paymentMethods, i => {
                                                return (
                                                    <Option value={i.id}>
                                                        <Flex direction='row'>
                                                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={getLogo(i)} />
                                                            {_.upperFirst(_.lowerCase(i.category))} ending in {_.get(i, 'last', 'XXXX')}
                                                        </Flex>
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </CardContent>
                                </Card>
                            </CardContent>
                                {(!subscriptionDetails?.total) || (subscriptionDetails?.total !== 0) || (subscriptionDetails?.total !== subscriptionDetails?.total) && <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Subtotal</Typography>
                                                <Typography variant='beta'>{currencyDisplay(subscriptionDetails?.total)}</Typography>                                                
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>}
                                {(!subscriptionDetails?.total) || (subscriptionDetails?.total !== 0) && <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Tax</Typography>
                                                <Typography variant='beta'>{currencyDisplay(subscriptionDetails?.total || 0)}</Typography>
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>}
                                <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Total Due Today</Typography>
                                                <Flex direction='row'>
                                                    <Typography variant='beta'>{currencyDisplay(subscriptionDetails?.total)}</Typography>
                                                    {(subscriptionDetails?.total === subscriptionDetails?.total) > 0 && <Typography style={{ marginLeft: 5 }} variant='pi'>+ tax</Typography>}
                                                    {(subscriptionDetails?.total > subscriptionDetails?.total) > 0 && <Typography style={{ marginLeft: 5 }} variant='pi'>tax included</Typography>}
                                                </Flex>
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>
                            </Flex>
                        </CardBody>}
                        <CardBody paddingBottom={2}>
                            <CardContent>
                                <CardTitle>
                                    {!props.summary && currentSubscription?.id && currentSubscription?.status === 'active' ? <CancelSubscriptionModal subscription={subscription} link dialog/> :
                                    <>
                                        {
                                            !onRetentionOffer && <Link children={`Modify ${Strings.subscription}`} disabled={loading} to={ScreenNames.Subscriptions} />
                                        }
                                    </>}
                                </CardTitle>                            
                            </CardContent>
                        </CardBody>
                    </> :
                    <>
                        <CardBody>
                            {dryRunIssueMessage && !loading && <CardContent>
                                <Typography variant={styleSettings.contentBox.lineHeaderVariant}>{dryRunIssueMessage}</Typography>
                                {/* {currentSubscription?.response?.id && <Button onClick={() => reactivateSubscription(currentSubscription.response.id)} style={{ marginTop: 10 }}>Reactivate</Button>} */}
                            </CardContent>}
                        </CardBody>
                    </>
                }

                {!loading && !subscription?.total && !loadingError &&
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`No ${Strings.subscription} Available`} style={{ marginTop: 10 }} />
                    </Flex>
                }

                {loadingError &&
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`${Strings.unableToLoad} ${Strings.subscription}`} style={{ marginTop: 10 }} />
                    </Flex>
                }
                
                <Divider />
                <CardBody>
                    {!loadingError && <>
                        {
                            !props.summary ? <Button {...styleSettings.cards.button} style={{ height: 'auto' }} children={`${Strings.change} ${Strings.subscription}`} fullWidth disabled={loading} onClick={() => history.push(ScreenNames.Subscriptions)} /> :
                            <Flex alignItems='left' direction='column' paddingTop={2} paddingBottom={2} style={{ width: '100%' }}>
                                
                                <Checkbox style={{ borderRadius: 0 }} name="terms" checked={this} onChange={() => setAgreeToTerms(prev => !prev)} error={showTermsError && 'Agree with the terms'} children={
                                    <Box style={{ marginTop: -3 }}>
                                        <Typography variant="pi" children={'I agree to the '} /> <Link href={clientConfig.contact.termsUrl} target='_blank' children={Strings.termsAndConditions} /> <Typography variant="pi" children={'and '} /> <Link href={clientConfig.contact.termsUrl} target='_blank' children={Strings.privacyPolicy} /> 
                                    </Box>
                                } />  
                                {clientConfig.customized.secondPurchaseConfirmationCheckbox.visible && <Checkbox style={{ borderRadius: 0 }} name="second" checked={this} onChange={() => setAgreeToSecondTerms(prev => !prev)} error={showSecondTermsError && 'Agree with the terms'} children={
                                    <Box style={{ marginTop: -3 }}>
                                        <Typography variant="pi" children={clientConfig.customized.secondPurchaseConfirmationCheckbox.text} />
                                    </Box>
                                } /> } 
                                <Flex direction='row' justifyContent='space-between' style={{ marginTop: 20 }}>
                                    <Button {...styleSettings.cards.button} children={Strings.purchase} fullWidth disabled={loading || dryRunIssue} onClick={() => submit({ specialBillPlan: specialBillPlan })} size='L' style={{ width: 150 }} />
                                    {/* <Flex direction='row'>
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={VisaLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={AmexLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={DiscoverLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={MastercardLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={DDALogo} />
                                    </Flex> */}
                                </Flex>
                            </Flex>
                        }
                    </>}
                    {loadingError && <Button {...styleSettings.cards.button} children={Strings.refresh} fullWidth disabled={loading} onClick={() => window.location.reload() } />}
                </CardBody>
                
            </Card>
        </>
    )
}