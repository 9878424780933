import { useState } from 'react';
import _ from 'lodash';
import { demoMode, apiDevModes } from '../config';
import { asyncTimeout } from '../utils/helpers';
import demoProductPackages from '../utils/data/demo/demoProductPackages.json';
import demoSubscription from '../utils/data/demo/demoSubscription.json';
import demoPaymentMethod from '../utils/data/demo/demoPaymentMethod.json';
import demoAccount from '../utils/data/demo/demoAccount.json';
import demoBillingHistory from '../utils/data/demo/demoBillingHistory.json';
import demoCreateAccount from '../utils/data/demo/demoCreateAccount.json';
import demoAccountSignIn from '../utils/data/demo/demoAccountSignIn.json';
import demoForgotPassword from '../utils/data/demo/demoForgotPassword.json';
import { v4 as uuidv4 } from 'uuid';

import { apiAccounts, apiInvoices, apiPaymentMethods, apiProducts, apiSubscriptions, apiTransactions } from '../services';

import { convertInvoices, convertProductPackages, convertSubscriptions, convertTransactions } from '../utils/middleware';

import useUser from './useUser';
import { apiCampaigns, apiCharges, apiCognito, apiGoogle, apiVault, certCaptureGetToken, certCaptureUpsertCustomer } from '../services/api';

const devMode = process.env.STAGE !== 'prod';

export default function useAPI() {

    const { setToken, setUser, logOut, user, setSubscription } = useUser();
    
    const fetchProductPackages = async( payload ) => {
        try {
            const res = await apiProducts.list();
            if(devMode) console.log({ api: 'fetchProductPackages', response: res });
            return res.data.data.products;
        } catch (error) {
            if(devMode) console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchSubscription = async(id) => {
        try {
            const res = await apiSubscriptions.get(id);
            if(devMode) console.log({ api: 'apiSubscriptions.get', response: res });
            return res.data.data.getSubscription;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchSubscriptions = async() => {
        try {
            const res = await apiSubscriptions.list();
            if(devMode) console.log({ api: 'apiSubscriptions.list', response: res });
            return res.data.data.getSubscriptionsByCustomerId;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchTransactions = async() => {
        try {
            const res = await apiTransactions.list();
            if(devMode) console.log({ api: 'apiTransactions.list', response: res });
            return res.data.data.getTransactionsByExternalId;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchInvoices = async() => {
        try {
            const res = await apiInvoices.list();
            if(devMode) console.log({ api: 'apiInvoices.list', response: res });
            return res.data.data.getInvoiceByCustomerId;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchInvoice = async( id ) => {
        try {
            const res = await apiInvoices.get( id );
            if(devMode) console.log({ api: 'apiInvoices.get', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const vaultAuthorize = async( payload ) => {
        try {
            const res = await apiVault.authorize();
            if(devMode) console.log({ api: 'vaultAuthorize', response: res });
            return res.data.data;
        } catch (error) {
            if(devMode) console.log({ error });
            throw error;
        }
    }

    const vaultFinalize = async( token ) => {
        try {
            const res = await apiVault.finalize( token );
            if(devMode) console.log({ api: 'vaultFinalize', response: res });
            return res.data.data;
        } catch (error) {
            if(devMode) console.log({ error });
            throw error;
        }
    }

    const userInfo = async() => {
        try {
            const token = localStorage.getItem('token');
            const res = await apiCognito.userInfo(token);
            if(devMode) console.log({ api: 'userInfo', response: res });
            return res.data.data;
        } catch (error) {
            if(devMode) console.log({ error });
            throw error;
        }
    }

    const createSubscription = async(payload) => {
        try {
            const res = await apiSubscriptions.create(payload);
            if(devMode) console.log({ api: 'apiSubscriptions.create', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const updateSubscription = async(id, payload) => {
        try {
            const res = await apiSubscriptions.update(id, payload);
            if(devMode) console.log({ api: 'apiSubscriptions.update', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const submitCancelSurvey = async(id, payload, dryrun = false) => {
        try {
            if(apiDevModes.submitCancelSurvey) {
                await asyncTimeout(1900);
                return true
            }

            const res = await apiSubscriptions.update(id, payload, dryrun);
            if(devMode) console.log({ api: 'apiSubscriptions.update', subscription: res.data.data });
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const createPaymentMethod = async(payload) => {
        try {
            const res = await apiPaymentMethods.create(payload);
            if(devMode) console.log({ api: 'apiPaymentMethods.create', response: res });
            return res.data.data.storePaymentMethod[0];
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchPaymentMethod = async( id ) => {
        try {
            const res = await apiPaymentMethods.get( id );
            if(devMode) console.log({ api: 'apiPaymentMethods.get', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchPaymentMethods = async() => {
        try {
            const res = await apiPaymentMethods.list();
            if(devMode) console.log({ api: 'apiPaymentMethods.list', response: res });
            return res.data.data.getPaymentMethods;
        } catch (error) {
            console.log({ error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            else throw error;
        }
    }

    const updatePaymentMethod = async(id, payload) => {
        try {
            if(apiDevModes.updatePaymentMethod) {
                await asyncTimeout(1900);
                return true;
            }
            const res = await apiPaymentMethods.update( id, payload );
            if(devMode) console.log({ api: 'apiPaymentMethods.update', paymentMethod: res.data.data });
            const account = await apiAccounts.get(user.id);
            if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });
            setUser(account.data.data);
            return res.data.data;
        } catch (error) {
            console.log({ api: 'updatePaymentMethod', error });
            if (error.response.data.errorMessage === 'AxiosError: Request failed with status code 401') return apiCognito.login();
            throw error;
        }
    }

    const fetchCampaign = async(id) => {
        try {
            const res = await apiCampaigns.get(id);
            if(devMode) console.log({ api: 'fetchCampaign.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const activateSubscription = async(id) => {
        try {
            const res = await apiSubscriptions.activate(id);
            if(devMode) console.log({ api: 'activateSubscription.post', subscription: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const refreshAccount = async({ accountId }) => {
        try {
            const res = await apiAccounts.refresh({ accountId });
            if(devMode) console.log({ api: 'refreshAccount.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const certCaptureToken = async(customer_number) => {
        try {
            const res = await certCaptureGetToken.get(customer_number);
            console.log({ api: 'certCaptureToken.get', token_data: res.data.data.response });
            return res.data.data.response;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const certCaptureCustomerUpsert = async(data) => {
        try {
            const res = await certCaptureUpsertCustomer.put(data);
            console.log({ api: 'certCaptureCustomerUpsert.put', customer: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const updateAccount = async(id, data) => {
        try {
            if(apiDevModes.fetchAccount) {
                await asyncTimeout(1800);
                return demoAccount;
            }

            const res = await apiAccounts.update(id, data);
            if(devMode) console.log({ api: 'apiAccounts.update', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const createCharge = async(data) => {
        try {
            const res = await apiCharges.create(data);
            if(devMode) console.log({ api: 'apiCharges.create', charge: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchBillingHistory = async(payload) => {
        try {
            if(apiDevModes.fetchBillingHistory) {
                await asyncTimeout(1900);
                return demoBillingHistory;
            }

            const res = await apiTransactions.list(payload);
            if(devMode) console.log({ api: 'apiTransactions.list', response: res });
            return convertTransactions(res.data.data);
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const cancelSubscription = async( id ) => {
        try {
            const res = await apiSubscriptions.cancel( id );
            if(devMode) console.log({ api: 'apiSubscriptions.cancel', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
    const purchaseSubscription = async( payload, dryRun = false ) => {
        try {
            if(apiDevModes.purchaseSubscription) {
                await asyncTimeout(1900);
                return demoSubscription;
            }

            const res = await apiSubscriptions.create( payload, dryRun);
            if(devMode) console.log({ api: 'apiSubscriptions.create', subscription: res.data.data });
            const account = await apiAccounts.get(user.id);
            if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });
            setUser(account.data.data);
            return res.data.data;
        } catch (error) {
            console.log({ method: 'useAPI@purchaseSubscription', error });
            throw error;
        }
    }

    const modifySubscription = async( id, payload, dryrun = false, effective_date, bill_prorated_period ) => {
        if(apiDevModes.updateSubscription) {
            await asyncTimeout(1900);
            return demoSubscription;
        }

        const res = await apiSubscriptions.modify( id, payload, dryrun, effective_date, bill_prorated_period );
        if(devMode) console.log({ api: 'apiSubscriptions.modify', subscription: res.data.data });
        const account = await apiAccounts.get(user.id);
        if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });        
        setUser(account.data.data);
        return res.data.data;
    }  

    const createAccount = async({ email, sub, username }) => {
        const res = await apiAccounts.create({ 
            externalId: sub,
            email,
            firstName: '',
            lastName: '',
            language: 'English',
            metadata: [
                {
                    key: 'cognito_username',
                    value: username
                }
            ]
        });
        
        if(devMode) console.log({ api: 'apiAccounts.create', response: res });

        return res.data.data.createCustomer;
    }

    const fetchAccount = async(id) => {
        const res = await apiAccounts.get(id);
        
        if(devMode) console.log({ api: 'apiAccounts.get', response: res, data: res.data, data2: res.data.data });

        return res.data.data.getCustomerByExternalId[0];
    }

    const signOut = async(data) => {
        if(apiDevModes.signOut) {
            logOut();
            return true;
        }

        logOut();
        return true;
    }

    const forgotPassword = async(data) => {
        if(apiDevModes.forgotPassword) {
            await asyncTimeout(1900);
            return demoForgotPassword;
        }
    }

    const resetPasswordWithCode = async(data) => {
        try {
            if(apiDevModes.resetPasswordWithCode) {
                await asyncTimeout(1900);
                return true
            }
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
    
    const submitRetentionOffer = async( id, payload, dryrun = false ) => {
        try {
            if(apiDevModes.submitRetentionOffer) {
                await asyncTimeout(1500);
                return true
            }

            const res = await apiSubscriptions.modify( id, payload, dryrun );
            if(devMode) console.log({ api: 'apiSubscriptions.modify', subscription: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchProduct = async(id) => {
        try {
            const res = await apiProducts.get(id);
            if(devMode) console.log({ api: 'apiProducts.get', response: res });
            return res.data.data.getProduct;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const oneTimePayment = async( payload ) => {
        try {
            const res = await apiInvoices.otp( payload );
            if(devMode) console.log({ api: 'apiInvoices.oneTimePayment', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const payInvoice = async( id, payload ) => {
        try {
            const res = await apiInvoices.pay( id, payload );
            if(devMode) console.log({ api: 'apiInvoices.pay', response: res });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const googleAddressAutocomplete = async({ types = 'geocode', input }) => {
        try {
            const res = await apiGoogle.placeAutocomplete({ types, input });
            return res.data?.predictions || [];
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    return {
        payInvoice,
        fetchProductPackages,
        fetchSubscription,
        fetchPaymentMethod,
        fetchSubscriptions,
        createPaymentMethod,
        fetchPaymentMethods,
        createSubscription,
        fetchAccount,
        updateAccount,
        fetchBillingHistory,
        updateSubscription,
        cancelSubscription,
        updatePaymentMethod,
        signOut,
        createCharge,
        createAccount,
        forgotPassword,
        resetPasswordWithCode,
        purchaseSubscription,
        submitCancelSurvey,
        fetchTransactions,
        fetchInvoices,
        fetchInvoice,
        submitRetentionOffer,
        modifySubscription,
        fetchProduct,
        googleAddressAutocomplete,
        fetchCampaign,
        certCaptureToken,
        certCaptureCustomerUpsert,
        refreshAccount,
        activateSubscription,
        vaultAuthorize,
        vaultFinalize,
        userInfo,
        oneTimePayment
    }
}
