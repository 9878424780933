import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { 
    Flex,
    Typography,
    Grid,
    GridItem,
    TextInput,
    Button
} from '@strapi/design-system';
import { 
    useToast
} from "native-base";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { Link } from '@strapi/design-system/Link';
import useAPI from "../../hooks/useAPI";
import useTheme from "../../hooks/useTheme";

import states from '../../utils/states.json';
import countries from '../../utils/countries.json';

import DDALogo from '../../assets/images/payment-icons/check2.svg';
import AmexLogo from '../../assets/images/payment-icons/amex.svg';
import VisaLogo from '../../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../../assets/images/payment-icons/mastercard.svg';

const getLogo = (paymentMethod) => {
    // console.log('getLogo - Input:', { paymentMethod });
    
    const brand = _.toLower(_.get(paymentMethod, 'brand'));
    
    if (_.isUndefined(brand)) return DefaultLogo;

    // console.log('getLogo - "brand":', { paymentMethod, brand });
    
    switch (brand) {
      case 'amex':
        return AmexLogo;
      case 'discover':
        return DiscoverLogo;
      case 'mastercard':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      case 'electroniccheck':
        return DDALogo;
      default:
        return DefaultLogo;
    }
};

export default function UpdatePaymentScreen(props) {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { theme } = useTheme();    
    const toast = useToast();
    const { isMobileTablet, isWidth } = useDeviceTypes();
    const { token, cart } = useUser();
    const { fetchPaymentMethod, updatePaymentMethod } = useAPI();
    const [paymentMethod, setPaymentMethod] = useState(_.get(history, 'location.state.paymentMethod'));
    const [onStateFocus, setOnStateFocus] = useState(false);
    const [onCountryFocus, setOnCountryFocus] = useState(false);
    const [line1, setLine1] = useState(null);

    // Get the card ID from URL : /payment-methods/:id/update
    const cardId = _.get(window.location.pathname.split('/'), 2);

    useEffect(() => {
        (async() => {
            setLoading(true);
            try {
                const paymentMethod = await fetchPaymentMethod(cardId);
                console.log({ paymentMethod });
                setPaymentMethod(paymentMethod);
            } catch (error) {
                console.log({ error });
            } finally {
                setLoading(false);
            }
        })();
    },[]);

    const schema = Yup.object().shape({
        account_holder: Yup.string().required('Card holder is required.'),
        address: Yup.string().required('Billing address is required.'),
        address2: Yup.string(),
        locality: Yup.string().required('City is required.'),
        region: Yup.string().required('State is required.'),
        postal: Yup.string().required('Zip code is required.'),
        country: Yup.string().required('Country required.'),
        expiration: Yup.string().required('Expiration Required').matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Must match format MM/YY").min(5, 'Expiration date invalid').max(5, 'Expirationd date invalid'),
    });

    const [submitting, setSubmitting] = useState(false);
    const submit = async(values) => {
        setSubmitting(true);
        try {
            const payload = {
                expiration: _.replace(values.expiration, '/', ''),
                billingDetails: {
                    accountHolder: {
                        firstName: _.split(values.account_holder, ' ')[0],
                        lastName: _.split(values.account_holder, ' ')[1],
                    },
                    address: values.address,
                    address2: values.address2,
                    country: values.country,
                    locality: values.locality,
                    region: values.region,
                    postal: values.postal,
                }
            };

            await updatePaymentMethod(paymentMethod.id, payload);

            toast.show({
                placement: 'top',
                title: 'Payment Method Updated',
                description: 'Successfully updated payment method',
            });

            // Go back to previous screen
            history.goBack();
        } catch (error) {
            console.log({ error });
            toast.show({
                placement: 'top',
                title: 'Payment Method Update Failed',
                description: "Failed to update payment method",
            });
        } finally {
            setSubmitting(false);
        }
    };

    const handlePlaceSelect = (e, setFieldValue) => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            {
                types: ["address"],
                fields: [
                  "address_components",
                  "formatted_address",
                  "geometry",
                  "name",
                ],
            }
        );

        autocomplete.addListener("place_changed", () => {
            const selectedPlace = autocomplete.getPlace();

            // Extract the address components
            const addressComponents = selectedPlace.address_components.reduce(
                (acc, component) => {
                const types = component.types;
                types.forEach((type) => {
                    if (type === "administrative_area_level_1") {
                    acc.state = component.short_name;
                    } else if (type === "country") {
                    acc.country = component.short_name;
                    } else if (type === "locality") {
                    acc.city = component.long_name;
                    } else if (type === "postal_code") {
                    acc.zip = component.long_name;
                    } else if (type === "street_number") {
                    acc.streetNumber = component.long_name;
                    } else if (type === "route") {
                    acc.streetName = component.long_name;
                    }
                });
                return acc;
                },
                {}
            );

            // Update the state with the extracted components
            setFieldValue('address',`${addressComponents.streetNumber} ${addressComponents.streetName}`);
            setFieldValue('region',addressComponents.state);
            setFieldValue('country',addressComponents.country);
            setFieldValue('postal',addressComponents.zip);
            setFieldValue('locality',addressComponents.city);

            setLine1(`${addressComponents.streetNumber} ${addressComponents.streetName}`);

        });
    };

    if(!token) history.push(ScreenNames.SignIn);
    if(!cardId) history.push(ScreenNames.Billing);
    return (
        <Flex direction="column" alignItems='center'>
            <Typography variant={styleSettings.pageHeaders.variant}>{Strings.updatePaymentDetails}</Typography>
            <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{Strings.paymentScreen.subtitle}</Typography>                
            <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                <Flex direction='column' style={{ width: '100%', marginBottom: 20 }}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            // account_holder: paymentMetho?.account_holder || '',
                            // address: prevPaymentMethod?.billing_address?.line1 || '',
                            // address2: prevPaymentMethod?.billing_address?.line2 || '',
                            // locality: prevPaymentMethod?.billing_address?.city || '',
                            // region: prevPaymentMethod?.billing_address?.district || '',
                            // postal: prevPaymentMethod?.billing_address?.postal_code || '',
                            // country: prevPaymentMethod?.billing_address?.country || '',
                            // expiration: `${prevPaymentMethod?.credit_card?.expiration_date.slice(4, 6)}/${prevPaymentMethod?.credit_card?.expiration_date.slice(2, 4)}` || ''
                        }}
                        onSubmit={submit}
                        validationSchema={schema}
                        validateOnChange={false}
                        >

                            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                                <Form id='mainForm' name='mainForm' onSubmit={handleSubmit} method="post">
                                    <Grid gap={{
                                        desktop: 5,
                                        tablet: 5,
                                        mobile: 5
                                    }} style={{ width: '100%' }}>
                                        <GridItem col={6} s={6}>
                                            <Flex direction='row' alignItems='center'>
                                                <img src={getLogo(paymentMethod)} style={{ width: 35, height: 35, marginRight: 10 }} />
                                                <Typography variant='beta'>ending in {_.get(paymentMethod, 'last', 'XXXX')}</Typography>
                                            </Flex>
                                        </GridItem>
                                        <GridItem col={12} s={12}>
                                            <TextInput 
                                                id="account_holder" 
                                                name="account_holder" 
                                                label="Account Holder" 
                                                placeholder="Account Holder"
                                                disabled={loading}
                                                style={styleSettings.overrides.textInput}
                                                error={errors.account_holder} 
                                                onChange={handleChange}
                                                value={values.account_holder}
                                            />
                                        </GridItem>
                                        <GridItem col={12} s={12}>
                                            <TextInput 
                                                id="expiration" 
                                                name="expiration" 
                                                label="Card Expiration" 
                                                placeholder="MM/YY"
                                                disabled={loading}
                                                style={styleSettings.overrides.textInput}
                                                error={errors.expiration} 
                                                onChange={handleChange}
                                                value={values.expiration}
                                            />
                                        </GridItem>
                                        <GridItem col={12} s={12} style={{ flexDirection: 'column' }}>
                                            <Typography variant="pi" fontWeight="bold" children={'Billing Address'} />
                                            <input
                                            id="autocomplete"
                                            name="address"
                                            type="text"
                                            placeholder=""
                                            value={values.address}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handlePlaceSelect(e, setFieldValue);                                            
                                            }}
                                            style={{
                                                width: '100%',
                                                fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                fontSize: '16px',
                                                color: theme === 'dark' ? 'white' : '#000',
                                                height: "47px",
                                                padding: "6px 12px",
                                                lineHeight: "1.42857",
                                                borderRadius: "5px",
                                                border: "1px solid #ddd",
                                                // background: '#121A27',
                                                marginTop: '5px'
                                            }}
                                            />
                                            {errors.address && <Typography textColor='danger600' variant="pi" children={errors.address} style={{ marginTop: 4}} />}
                                        </GridItem>
                                        <GridItem col={12} s={12}>
                                            <TextInput 
                                                id="address2" 
                                                name="address2" 
                                                label="Billing Address Line 2" 
                                                disabled={loading}
                                                style={styleSettings.overrides.textInput}
                                                error={errors.address2} 
                                                onChange={handleChange}
                                                value={values.address2}
                                            />
                                        </GridItem>
                                        <GridItem col={6} s={6}>
                                            <TextInput 
                                                id="locality" 
                                                name="locality" 
                                                label="City" 
                                                disabled={loading}
                                                style={styleSettings.overrides.textInput}
                                                error={errors.locality} 
                                                onChange={handleChange}
                                                value={values.locality}
                                            />
                                        </GridItem>
                                        <GridItem col={6} s={6}>
                                            <Flex alignItems='flex-start' direction='column'>
                                                <Typography variant="pi" fontWeight="bold" children={'State'} style={{ marginBottom: 4}} />
                                                <select
                                                    name="region"
                                                    onChange={handleChange}
                                                    value={values.region}
                                                    onFocus={() => setOnStateFocus(true)}
                                                    onBlur={() => setOnStateFocus(false)}
                                                    style={{
                                                        height: 47,
                                                        width: '100%',
                                                        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                        fontSize: 14,
                                                        color: theme === 'dark' ? 'white' : '#000',
                                                        padding:"6px 12px",
                                                        lineHeight: 1.42857,
                                                        borderRadius: 5,
                                                        border: onStateFocus ? '3px solid #7CD6F7' : '1px solid #ddd',
                                                        background: '#fff',
                                                        outline: onStateFocus && 0
                                                        
                                                    }}
                                                >
                                                    <option value={''}>{}</option>
                                                    <option value={'International'}>{'International'}</option>
                                                    {_.map(states, i => <option key={i.abbreviation} value={i.abbreviation}>{i.name}</option>)}
                                                </select>
                                                {errors.region && <Typography textColor='danger600' variant="pi" children={errors.region} style={{ marginTop: 4}} />}
                                            </Flex>
                                        </GridItem>
                                        <GridItem col={6} s={6}>
                                            <TextInput
                                                id="postal" 
                                                name="postal" 
                                                label="Zip" 
                                                disabled={loading}                                            
                                                style={styleSettings.overrides.textInput}
                                                error={errors.postal} 
                                                onChange={handleChange}
                                                value={values.postal}
                                            />
                                        </GridItem>
                                        <GridItem col={6} s={6}>
                                            <Flex alignItems='flex-start' direction='column'>
                                                <Typography variant="pi" fontWeight="bold" children={'Country'} style={{ marginBottom: 4}} />
                                                <select
                                                    name="country"
                                                    onChange={handleChange}
                                                    value={values.country}
                                                    onFocus={() => setOnCountryFocus(true)}
                                                    onBlur={() => setOnCountryFocus(false)}
                                                    style={{
                                                        height: 47,
                                                        width: '100%',
                                                        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                        fontSize: 14,
                                                        color: theme === 'dark' ? 'white' : '#000',
                                                        padding:"6px 12px",
                                                        lineHeight: 1.42857,
                                                        borderRadius: 5,
                                                        border: onCountryFocus ? '3px solid #7CD6F7' : '1px solid #ddd',
                                                        background: '#fff',
                                                        outline: onCountryFocus && 0
                                                        
                                                    }}
                                                >
                                                    <option value={''}>{}</option>
                                                    {_.map(countries, i => <option value={i.code}>{i.name}</option>)}
                                                </select>
                                                {errors.country && <Typography textColor='danger600' variant="pi" children={errors.country} style={{ marginTop: 4}} />}
                                            </Flex>
                                        </GridItem>
                                        <GridItem col={12} s={12}>
                                            <Button type='submit' fullWidth loading={loading || submitting} disabled={loading || submitting} size='L' onClick={handleSubmit} style={{ marginTop: 15 }}>{'Save Payment Method'}</Button>
                                        </GridItem>
                                        
                                    </Grid>
                                </Form>
                            )}
                    </Formik>
                </Flex>
                <Link to={ScreenNames.PurchaseConfirmation}>
                    Cancel
                </Link>
            </Flex>            
        </Flex>
    )
}