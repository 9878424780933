import React, { useEffect, useState } from "react";
import { default as clientConfig } from "../clientConfig";
import _ from 'lodash';
import moment from "moment";
import { styleSettings } from "../config";
import { useHistory, useLocation } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Box } from '@strapi/design-system/Box';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardSubtitle, CardBadge, CardTitle, CardContent, CardHeader } from '@strapi/design-system/Card';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import Strings from "../constants/Strings";

import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import ScreenNames from "../constants/ScreenNames";

export default function SubscriptionNowActiveBox(props){ 

    const history = useHistory();
    const location = useLocation();
    const { user, redirect_uri } = useUser();
    const { fetchSubscription } = useAPI();

    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState(location.state.subscription);
    // Get state from props
    console.log('subscription',subscription);

    const Frequency = {
        daily: 'daily',
        annually: 'annually',
        monthly: 'monthly',
        once: 'once',
        quarterly: 'quarterly',
        semiannual: 'semiannual',
    }    

    const calculateNextBillDate = (frequency) => {
        switch (frequency) {
            case Frequency.daily:
                return moment().add(1, 'day').format('MMMM DD, YYYY')
            case Frequency.monthly:
                return moment().add(1, 'month').format('MMMM DD, YYYY')
            case Frequency.annually:
                return moment().add(1, 'year').format('MMMM DD, YYYY')
            case Frequency.once:
                return moment().format('MMMM DD, YYYY')
            case Frequency.quarterly:
                return moment().add(3, 'month').format('MMMM DD, YYYY')
            case Frequency.semiannual:
                return moment().add(6, 'month').format('MMMM DD, YYYY')
            default:
                return moment().format('MMMM DD, YYYY')
        }
    }

    return (
        <>
            {!loading ? 
                <Card>
                    <CardHeader padding={4} paddingLeft={10} paddingRight={10}>
                        <CardTitle variant='delta'>
                            {'Your Subscription is Now Active'}
                        </CardTitle>
                    </CardHeader>
                    {/* <CardBody padding={2}>
                        <CardContent style={{ width: '100%' }} >
                            <Flex direction='row' justifyContent='space-between' paddingTop={2} paddingBottom={5}>
                                <Flex direction='column' alignItems='left'>
                                    <Typography variant='beta'>Next Bill Date</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>{moment(subscription.nextBillDate).add(1, 'day').format('MMMM DD, YYYY')}</Typography>
                                </Flex>
                                <Box>
                                    <Button variant='secondary' children={'Manage Subscription'} onClick={() => history.push(ScreenNames.Billing)} />
                                </Box>
                            </Flex>
                        </CardContent>
                    </CardBody> */}
                    <CardBody padding={2} direction='column'>
                        <Button children={'Continue to ' + Strings.clientContact.name} size='M' fullWidth onClick={() => window.location.href = redirect_uri || clientConfig.contact.successRedirectUrl } />
                        <Link variant="primary500" style={{ marginTop: 10, alignSelf: 'center' }} children={'Manage Subscription'} size='M' fullWidth onClick={() => history.push(ScreenNames.Billing)} />
                    </CardBody>
                </Card> : <Loader />
            }
        </>
    )
}