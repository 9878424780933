import React from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { Link } from '@strapi/design-system/Link';
import RebarPaymentForm from "../../components/RebarPaymentForm";

export default function AddPaymentScreen(props) {

    const history = useHistory(); 
    const { isMobileTablet } = useDeviceTypes();
    const { token } = useUser();

    // Props for the PaymentForm component
    const paymentFormProps = {
        addressOn: true, // Show or hide address fields
        titlesOn: true, // Show or hide titles
        labelsOn: false, // Show or hide labels
        firstName: '', // Prefill first name if known
        lastName: '', // Prefill last name if known
        address1: '', // Prefill address1 if known
        address2: '', // Prefill address2 if known
        city: '', // Prefill city if known
        state: '', // Prefill state if known
        zip: '', // Prefill zip if known
        
        // Callback function to handle success
        onSuccess: async(e) => {
            console.log('Success', e);
            history.goBack();
        },
        
        // Callback function to handle failure
        onFailure: (e) => {
            console.log('Failure', e);
        },
    };

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column" alignItems='center'>
            <Typography variant={styleSettings.pageHeaders.variant}>{Strings.addPaymentMethod}</Typography>
            <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{Strings.paymentScreen.subtitle}</Typography>                
            <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                <RebarPaymentForm {...paymentFormProps} />
                <Link onClick={() => history.goBack()} style={{ marginTop: 20 }}>
                    Cancel
                </Link>
            </Flex>            
        </Flex>
    )
}