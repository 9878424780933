import React, { useState, useRef } from "react";
import _ from "lodash";
import { useHistory, Router } from "react-router-dom";
import { authTypes, styleSettings } from '../config';
import { ModalLayout, ModalBody, ModalHeader } from '@strapi/design-system/ModalLayout';
import { Typography } from '@strapi/design-system/Typography';
import { Flex } from '@strapi/design-system/Flex';
import { Card, CardHeader, CardBody } from '@strapi/design-system/Card';
import { Box } from '@strapi/design-system/Box';
import { Divider } from '@strapi/design-system/Divider';
import { TabGroup, Tabs, Tab, TabPanels, TabPanel } from '@strapi/design-system/Tabs';
import { SimpleMenu, MenuItem } from '@strapi/design-system/SimpleMenu';

import AuthForm from "./AuthForm";

import { asyncTimeout, scrollTop } from "../utils/helpers";

import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import useDeviceTypes from "../hooks/useDeviceTypes";

import Strings from "../constants/Strings";
import ScreenNames from "../constants/ScreenNames";

const tabs = [
    {
        id: 0,
        text: Strings.signIn
    },
    {
        id: 1,
        text: Strings.createAccount
    },
    {
        id: 2,
        text: Strings.forgotPassword
    },
]

export default function AuthBox(props) {

    const history = useHistory();

    const { isMobileTablet } = useDeviceTypes();

    const { token: tokenData, logOut, getToken, user } = useUser();

    const [loading, setLoading] = useState(false);
    //const [isVisible, setIsVisible] = useState(props.isVisible);
    const [link] = useState(props.link);
    const [token, setToken] = useState(tokenData);
    const [menuValue, setMenuValue] = useState('');
    const [isVisible, setIsVisible] = useState(() => token ? false : true);

    const [initialSelectedTabIndex, setInitialSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedtab] = useState(initialSelectedTabIndex);

    //Form
    const [formLoading, setFormLoading] = useState(false);
    const authFormRef = useRef();

    const refreshForToken = () => {
        setToken(() => getToken());
    }

    useState(() => {
        (async() => {
            
        })();
    },[]);

    return <>         
        <Flex direction='column' style={{ width: isMobileTablet ? '100%' : '50%' }} >
            <Typography variant="alpha" fontWeight="bold" textColor="neutral800" as="h2" style={{ marginBottom: 20 }}>
                {tabs[selectedTab].text}
            </Typography>  
            <TabGroup style={{ width: '100%'}} label="Some stuff for the label" id="tabs" variant="simple" initialSelectedTabIndex={0} onTabChange={setSelectedtab}>
                <Tabs>
                    {_.map(tabs, tab => <Tab disabled={loading} style={{ width: !isMobileTablet && '33.33%' }} key={tab.id}>{tab.text}</Tab>)}
                </Tabs>
                <Divider />
                <TabPanels>
                    <TabPanel>
                        <Box paddingTop={7}>
                            <AuthForm
                                type={authTypes.SIGN_IN}
                                onError={(error) => { 
                                    setLoading(false);
                                    console.log({error}); 
                                }} 
                                onComplete={(res) => {
                                    refreshForToken();
                                    setLoading(false); 
                                    setIsVisible(false); 
                                    scrollTop();
                                    history.push(ScreenNames.Billing);
                                    //window.location.href = ScreenNames.Billing
                                }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box paddingTop={7}>
                            <AuthForm
                                type={'create_account'}
                                onError={(error) => { 
                                    console.log({error}); 
                                }} 
                                onComplete={(res) => { 
                                    refreshForToken();
                                    setLoading(false); 
                                    setIsVisible(false); 
                                    scrollTop();
                                    history.push(ScreenNames.Subscriptions);
                                    //window.location.href = ScreenNames.Subscriptions
                                }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box paddingTop={7}>
                            <AuthForm
                                type={'forgot_password'}
                                onError={(error) => { 
                                    console.log({error}); 
                                }} 
                                onComplete={(res) => { 
                                    refreshForToken();
                                    setLoading(false); 
                                    setIsVisible(false); 
                                    scrollTop();
                                }}
                            />
                        </Box>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </Flex>
    </>;
}