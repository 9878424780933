import { useEffect, useState } from "react";
import _ from 'lodash';
import { 
  Flex,
  HStack,
  Heading,
  useToast,
} from "native-base";
import Strings from "../constants/Strings";
import clientConfig from "../clientConfig";
import { Radio, RadioGroup } from '@strapi/design-system/Radio';
import { Typography } from "@strapi/design-system";
import { Button } from "@strapi/design-system";
import { Loader } from "@strapi/design-system";
import { Checkbox } from "@strapi/design-system";
import { Box } from "@strapi/design-system";
import { Link } from "@strapi/design-system";
import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";

import { apiVault } from "../services/api";

import DDALogo from '../assets/images/payment-icons/check2.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';
import { Image } from "react-native";

const { 
  REACT_APP_VAULT_API_KEY, // DO NOT EXPOSE THIS API KEY IN THE FRONTEND !!!!!!!!!!!!!!!!
  REACT_APP_VAULT_DOMAIN,
  REACT_APP_STAGE, // dev, stg, prd
  REACT_APP_VAULT_URL,
  REACT_APP_VAULT_KEY,
} = process.env;

_.isJson = function (str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export default function RebarPaymentForm ({
  addressOn = true, // true or false
  titlesOn = true, // true or false
  labelsOn = false, // true or false
  firstName = '',
  lastName = '',
  address1 = '',
  address2 = '',
  city = '',
  state = '',
  zip = '',
  buttonText = 'SUBMIT',
  onSuccess, 
  onFailure,
  createPM = true,
}){

  const { createPaymentMethod } = useAPI();
  const { user } = useUser();

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [showTermsError, setShowTermsError] = useState(false);

  const toast = useToast();

  const [customer, setCustomer] = useState({});

  const [type, setType] = useState("card");

  const [vaultToken, setVaultToken] = useState('');

  const vaultUrl = () => {
    return REACT_APP_VAULT_URL;
  }

  const getLogo = (paymentMethod) => {
    const brand = _.toLower(_.get(paymentMethod, 'brand'));
    
    if (_.isUndefined(brand)) return DefaultLogo;
        
    switch (brand) {
      case 'amex':
        return AmexLogo;
      case 'discover':
        return DiscoverLogo;
      case 'mastercard':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      case 'electroniccheck':
        return DDALogo;
      default:
        return DefaultLogo;
    }
  };

  // THIS AUTHORIZE FUNCTION IS ONLY FOR DEMO PURPOSES ONLY - NEVER SHOULD BE USED IN THE FRONTEND !!!!! 
  // CREATE A BACKEND API TO AUTHORIZE AND RECEIVE A TOKEN FROM THE VAULT API
  // REPLACE THIS AUTHORIZE FUNCTION WITH YOUR OWN BACKEND API CALL
  const authorize = async() => {
    try {
      const { data } = await apiVault.authorize();
      setVaultToken(data.data.data.token);
    } catch (error) {
      console.log(error);
    }
  };
  // END OF AUTHORIZE FUNCTION

  const finalize = async() => {
    try {
      const { data } = await apiVault.finalize( vaultToken );           

      const e = data.data.data;

      // Incoming data from the payment form
      // {
      //     "type": "card",
      //     "token": "fb9ca3a0-d785-4201-acd3-8a52cc94c139",
      //     "brand": "visa",
      //     "scheme": "credit",
      //     "expiration": "0129",
      //     "address": "33 Main St",
      //     "address2": "",
      //     "city": "Indianapolis",
      //     "zip": "46250",
      //     "last": "4242",
      //     "issuer_id": "424242"
      //   }

      // Region, account holder is not returned from payment iframe
      
      // Create the customer payment method
      let paymentMethod = {};
      if(e.type === 'card') {
        paymentMethod = {
          last: e.last,
          token: e.token,
          issuer_id: e.issuer_id,
          expiration: e.expiration,
          primary: true,
          brand: e.brand,
          category: 'CREDIT',
          customerId: user.externalId,
          billingDetails: {
            address: e.address,
            address2: e.address2,
            country: 'USA',
            locality: e.city,
            region: e.state,
            postal: e.zip,
            accountHolder: {
              firstName: '',
              lastName: '',
            }
          }
        };
      } else {
        paymentMethod = {
          last: e.last,
          token: e.token,
          primary: true,
          brand: e.brand,
          category: 'DDA',
          customerId: user.externalId,
          billingDetails: {
            address: null,
            address2: null,
            country: 'USA',
            locality: null,
            region: 'IN',
            postal: '46250',
            accountHolder: {
              firstName: e.firstName,
              lastName: e.lastName,
            }
          }
        };
      }

      console.log({ paymentMethod });

      if(!createPM) return onSuccess(paymentMethod)

      // Create the payment method
      const response = await createPaymentMethod(paymentMethod);
      console.log({ response });
      if(response.code !== '101') {
        toast.show({
          placement: 'top',
          title: _.upperFirst(response.message),
          description: "Failed to create payment method",
        });
        onFailure(response);
        authorize();
        return;
      }

      toast.show({
        placement: 'top',
        title: "Success",
        description: "Successfully saved payment method",
      });

      onSuccess(e);
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
      toast.show({
        placement: 'top',
        title: "Failure",
        description: "Failed to create payment method",
      });
      onFailure(error);
    }
  };

  const [cardIframeLoaded, setCardIframeLoaded] = useState(false);
  const vaultCardIframe = () => {
    return (
      <>
        {!cardIframeLoaded && (
          <Flex>
            <HStack space={2} justifyContent="center">
              <Loader accessibilityLabel="Loading" />
            </HStack>
          </Flex>
        )}

        <iframe
          title="card-iframe"
          onLoad={() => setCardIframeLoaded(true)}
          style={{
            width: '100%',
            height: '400px',
            border: 'none'
          }}
          scrolling="no"
          src={`${(vaultUrl())}/card/${vaultToken}?address=${addressOn}&titles=${titlesOn}&labels=${labelsOn}&firstName=${customer.firstName}&lastName=${customer.lastName}&address1=${customer.address1}&address2=${customer.address2}&addressCity=${customer.city}&addressState=${customer.state}&addressZip=${customer.zip}`}
        ></iframe>
      </>
    );
  };

  const [bankIframeLoaded, setBankIframeLoaded] = useState(false);
  const vaultBankIframe = () => {
    return (
      <>
        {!bankIframeLoaded && (
          <Flex>
            <HStack space={2} justifyContent="center">
              <Loader accessibilityLabel="Loading" />
            </HStack>
          </Flex>
        )}

        <iframe
          title="bank-iframe"
          onLoad={() => setBankIframeLoaded(true)}
          style={{
            width: '100%',
            height: '225px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl()}/bank/${vaultToken}?titles=${titlesOn}&labels=${labelsOn}&firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const [authorizing, setAuthorizing] = useState(false);
  useEffect(() => {
    (async() => {
      setAuthorizing(true);
      await authorize();
      setCustomer({
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zip
      })
      setAuthorizing(false);
    })();
  },[type]);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const submit = async() => {
    setSubmitting(true);
    await finalize();
    setSubmitting(false);
  };

  useEffect(() => {
    const handleIframeEvent = (event) => {
      console.log({ message: 'From postMessage API', event });

      const eventData = _.get(event, 'data');
      const eventObject = _.isJson(eventData)
        ? JSON.parse(eventData)
        : eventData;

      // console.log({ eventObject });

      if (_.get(eventObject, 'detail.message') === 'complete')
        setSubmitDisabled(false);
      else setSubmitDisabled(true);
    };

    window.addEventListener('message', handleIframeEvent);

    // cleanup this component
    return () => {
      window.removeEventListener('message', handleIframeEvent);
    };
  }, []);

  return (
    <Flex direction="column" width={500}>      
      {!authorizing ? (
        <Flex direction="column">
          {/* Select Type */}
          {(bankIframeLoaded || cardIframeLoaded) && <Typography variant="beta" style={{ marginBottom: 10 }}>Select Account Type</Typography>}
          {vaultToken && (bankIframeLoaded || cardIframeLoaded) && <RadioGroup name="type" marginBottom={5} accessibilityLabel="Card or Bank" value={type} onChange={nextValue => {
            setType(nextValue.target.value);
          }}>
            <Radio value="card">
              <Typography variant='epsilon'>Card</Typography>
            </Radio>
            <Radio value="bank">
              <Typography variant='epsilon'>Bank Account</Typography>
            </Radio>
          </RadioGroup>}

          {(bankIframeLoaded || cardIframeLoaded) && <Typography variant="beta" style={{ marginBottom: 5, marginTop: 25 }}>Payment Details</Typography>}
          <Flex direction='row' marginTop={2} marginBottom={2}>
            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'visa' })} />
            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'mastercard' })} />
            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'discover' })} />
            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'amex' })} />
          </Flex>
          
          {/* Card Iframe */}
          {vaultToken && (type === 'card') && vaultCardIframe()}

          {/* Bank Iframe */}
          {vaultToken && (type === 'bank') && vaultBankIframe()}

          {vaultToken && (bankIframeLoaded || cardIframeLoaded) &&
          <>
            <Checkbox style={{ borderRadius: 0, marginBottom: 20 }} name="terms" checked={this} onChange={() => setAgreeToTerms(prev => !prev)} error={showTermsError && 'Agree with the terms'} children={
              <Box style={{ marginTop: -3 }}>
                <Typography variant="pi" children={'I agree to the '} /> <Link href={clientConfig.contact.termsUrl} target='_blank' children={Strings.termsAndConditions} />
              </Box>
            } /> 
              <Button
                loading={submitting}
                disabled={!agreeToTerms || submitDisabled}
                onClick={submit}
                size='L'
                fullWidth
              >
                {buttonText}
              </Button>
          </>
          }

        </Flex>
      ) : (
        <Flex>
          <HStack space={2} justifyContent="center">
            <Loader accessibilityLabel="Loading" />
          </HStack>
        </Flex>
      )}
    </Flex>
  )
}
