import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import {
  Flex,
  Typography,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardContent,
  CardHeader,
  CardAction,
  CardFooter,
  CardBadge,
  CardSubtitle,
  CardTitle,
  Loader,
  Radio,
  RadioGroup,
  Button,
  Link,
} from '@strapi/design-system';
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import useAPI from "../../hooks/useAPI";
import { currencyDisplay } from "../../utils/helpers";
import { Image } from "react-native";
import { Check, CheckCircle, Cross, CrossCircle } from "@strapi/icons";

import DDALogo from '../../assets/images/payment-icons/check2.svg';
import AmexLogo from '../../assets/images/payment-icons/amex.svg';
import VisaLogo from '../../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../../assets/images/payment-icons/mastercard.svg';

export default function PayNowScreen(props) {

  const history = useHistory(); 
  const { isMobileTablet, isWidth } = useDeviceTypes();
  const { token } = useUser();
  const { fetchInvoice, fetchPaymentMethods, payInvoice } = useAPI();
  const [loading, setLoading] = useState(true);
  // Get invoice data from history
  const [invoice, setInvoice] = useState(_.get(history, 'location.state.invoice'));
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  // Get the invoice ID from URL : /invoices/:id/pay
  const invoiceId = _.get(window.location.pathname.split('/'), 2);

  const getInvoice = async() => {
    setLoading(true);
    try {
      const res = await fetchInvoice(invoiceId);
      setInvoice(res);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }

  }

  const getPaymentMethods = async() => {
    setLoadingPaymentMethods(true);
    try {
      const res = await fetchPaymentMethods();
      setPaymentMethods(res);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingPaymentMethods(false);
    }

  }
    const [submittingPayment, setSubmittingPayment] = useState(false);
    const submitPayment = async() => {
      setSubmittingPayment(true);
      try {
        const payload = {
          amount: invoice.balance,
          token: paymentMethod,
          confirmation: invoice.confirmation,
          currency: invoice.lineItems[0].currency,
          paymentMethodIds: [
            _.find(paymentMethods, { token: paymentMethod }).id
          ]
        }
        const res = await payInvoice(invoice.id, payload);
        console.log('payInvoice', res);
        setPaymentSuccess(true);
      } catch (error) {
        console.log(error);
        setPaymentFailed(true);
        alert('There was an error processing your payment. Please try again.');
      } finally {
        setSubmittingPayment(false);
      }
    }

  const getLogo = (paymentMethod) => {
    const brand = _.toLower(_.get(paymentMethod, 'brand'));
    
    if (_.isUndefined(brand)) return DefaultLogo;
        
    switch (brand) {
      case 'amex':
        return AmexLogo;
      case 'discover':
        return DiscoverLogo;
      case 'mastercard':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      case 'electroniccheck':
        return DDALogo;
      default:
        return DefaultLogo;
    }
  };

  useEffect(() => {
    if(!invoiceId) history.push(ScreenNames.Billing);
    console.log('invoice', invoice);
    console.log('invoiceId', invoiceId);
    getInvoice();
    getPaymentMethods();
  },[]);

  if(!token) history.push(ScreenNames.SignIn);
  return (
    <Flex direction="column" alignItems='center'>
      <Typography variant={styleSettings.pageHeaders.variant}>Invoice #{invoice.number}</Typography>
      <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{invoice.description}</Typography>                
      <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : 900, marginTop: 30 }}>
        {loading ?
        <Loader /> :
          <Grid style={{ width: '100%', }} gap={1} padding={1}>
            {!paymentSuccess && <GridItem col={4} s={12}>            
              <Grid gap={1} padding={1} style={{ width: '100%' }}>
              <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                      <CardContent>
                        <CardTitle variant="omega">Payment Method</CardTitle>
                      </CardContent>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                      <CardContent>
                        <CardTitle>Credit Card</CardTitle>
                      </CardContent>
                    </CardBody>                
                    <CardBody>
                      <CardContent>
                        <RadioGroup labelledBy="payment-methods" onChange={e => setPaymentMethod(e.target.value)} value={paymentMethod} name="paymentMethod">
                          {
                            _.map(paymentMethods, (pm, index) => {
                              if(pm.category === 'CREDIT')
                              return (
                                <Radio key={index} value={pm.token}>
                                  <Flex direction='row'>
                                    <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={getLogo(pm)} />
                                    ending in {_.get(pm, 'last', 'XXXX')}
                                  </Flex>
                                </Radio>
                              )
                            })
                          }
                        </RadioGroup>
                      </CardContent>
                    </CardBody>
                    <CardBody>
                      <CardContent>
                        <CardTitle>{'Bank (ACH)'}</CardTitle>
                      </CardContent>
                    </CardBody>
                    <CardBody>
                      <CardContent>
                        <RadioGroup labelledBy="payment-methods" onChange={e => setPaymentMethod(e.target.value)} value={paymentMethod} name="paymentMethod">
                          {
                            _.map(paymentMethods, (pm, index) => {
                              if(pm.category === 'DDA')
                              return (
                                <Radio key={index} value={pm.token}>
                                  <Flex direction='row'>
                                    <Image style={{ width: 30, height: 25, resizeMode: 'contain', marginRight: 5 }} source={getLogo(pm)} />
                                    account ending in {_.get(pm, 'last', 'XXXX')}
                                  </Flex>
                                </Radio>
                              )
                            })
                          }
                        </RadioGroup>
                      </CardContent>
                    </CardBody>
                    <CardBody>
                      <CardContent>
                      <Link
                        style={{ height: 'auto' }}
                        children={'Add Payment Method'}
                        fullWidth
                        onClick={() => history.push(ScreenNames.AddPayment)}
                        disabled={false}/>
                      </CardContent>
                    </CardBody>
                  </Card>              
                </GridItem>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                        <CardContent>
                          <Flex direction='row'>
                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'visa' })} />
                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'mastercard' })} />
                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'discover' })} />
                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 0 }} source={getLogo({ brand: 'amex' })} />
                          </Flex>
                        </CardContent>
                      </CardBody>
                  </Card>
                </GridItem>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                      <CardContent style={{ width: '100%' }}>
                        <Button onClick={submitPayment} loading={submittingPayment} disabled={!paymentMethod || submittingPayment} fullWidth>
                          Pay {currencyDisplay(_.get(invoice, 'balance', '1'))}
                        </Button>
                      </CardContent>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem col={12} style={{ alignItems: 'center'}}>
                  <Link to={ScreenNames.Billing}>
                    Cancel
                  </Link>
                </GridItem>
              </Grid>
            </GridItem>}
            {!paymentSuccess && <GridItem col={8} s={12}>
              <Grid gap={1} padding={1} style={{ width: '100%' }}>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                      <CardContent>
                        <CardTitle>{invoice.customer.firstName} {invoice.customer.lastName}</CardTitle>
                        <CardSubtitle>{invoice.customer.email}</CardSubtitle>
                      </CardContent>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    {
                      _.map(invoice.lineItems, (lineItem, index) => (
                        <CardBody key={index}>
                          <CardContent>
                            <CardTitle>{_.get(lineItem, 'productName', 'n/a')}</CardTitle>
                            <CardSubtitle>{_.get(lineItem, 'productDescription', 'n/a')}</CardSubtitle>
                            <CardSubtitle>{currencyDisplay(_.get(lineItem, 'amount', '1'))} x {_.get(lineItem, 'quantity', '1')}</CardSubtitle>
                          </CardContent>
                          <CardBadge>
                            {currencyDisplay(
                              _.get(lineItem, 'amount', '1') * _.get(lineItem, 'quantity', '1')
                            )}
                          </CardBadge>
                        </CardBody>
                      ))
                    }
                  </Card>
                </GridItem>
                <GridItem col={12}>
                  <Card style={{ width: '100%' }}>
                    <CardBody>
                      <CardContent>
                        <CardTitle>Tax</CardTitle>
                      </CardContent>
                      <CardBadge>
                        {currencyDisplay(_.get(invoice, 'totalTax', '1'))}
                      </CardBadge>
                    </CardBody>
                    <CardBody>
                      <CardContent>
                        <CardTitle>Total</CardTitle>
                      </CardContent>
                      <CardBadge>
                        {currencyDisplay(_.get(invoice, 'total', '1'))}
                      </CardBadge>
                    </CardBody>
                    <CardBody>
                      <CardContent>
                        <CardTitle>Amount Due</CardTitle>
                      </CardContent>
                      <CardBadge backgroundColor='success500' textColor='neutral100'>
                        {currencyDisplay(_.get(invoice, 'balance', '1'))}
                      </CardBadge>
                    </CardBody>
                  </Card>
                </GridItem>                
              </Grid>
            </GridItem>}
            {paymentSuccess && <GridItem col={12}>
              <Flex direction='column'>
                <Typography variant="alpha" textColor="primary500" style={{ textAlign: 'center'}}>
                  <CheckCircle />
                </Typography>
                <Typography variant="alpha" textColor="success500" style={{ textAlign: 'center'}}>
                  Payment Successful
                </Typography>
                <Typography variant="omega" style={{ textAlign: 'center'}}>
                  Thank you for your payment!
                </Typography>
              </Flex>
            </GridItem>}
            {/* <GridItem col={12}>
              <Flex direction='column'>
                <Typography variant="alpha" textColor="primary500" style={{ textAlign: 'center'}}>
                  <CrossCircle />
                </Typography>
                <Typography variant="alpha" textColor="danger500" style={{ textAlign: 'center'}}>
                  Payment Failed
                </Typography>
                <Typography variant="omega" style={{ textAlign: 'center'}}>
                  There was an error processing your payment. Please try again.
                </Typography>
              </Flex>
            </GridItem> */}
          </Grid>
        }
      </Flex>
    </Flex>
  )
}