import HomeScreen from "../screens";
import SubscriptionsScreen from "../screens/Subscriptions";
import PaymentScreen from "../screens/Payment";
import BillingScreen from "../screens/Billing";
import PurchaseConfirmationScreen from "../screens/PurchaseConfirmation";
import SettingsScreen from "../screens/Settings";
import PurchaseSuccessScreen from "../screens/PurchaseSuccess";
import SignInScreen from "../screens/SignIn";
import VerifyScreen from "../screens/Verify";
import UpdatePaymentScreen from "../screens/UpdatePayment";
import AddPaymentScreen from "../screens/AddPayment";
import RetentionOfferScreen from "../screens/RetentionOffer";
import UpdatePaymentManageScreen from "../screens/UpdatePaymentManage";
import AddPaymentManageScreen from "../screens/AddPaymentManage";
import CardFailureScreen from '../screens/CardFailure';
import MobileSubscriptionRedirectScreen from "../screens/MobileSubscriptionRedirect";
import CertCaptureScreen from "../screens/CertCapture";
import PayNowScreen from "../screens/PayNow";
import OneTimePaymentScreen from "../screens/OneTimePayment";
import ErrorScreen from "../screens/Error";

export default {
    HomeScreen,
    SubscriptionsScreen,
    PaymentScreen,
    BillingScreen,
    PurchaseConfirmationScreen,
    SettingsScreen,
    PurchaseSuccessScreen,
    SignInScreen,
    VerifyScreen,
    UpdatePaymentScreen,
    AddPaymentScreen,
    RetentionOfferScreen,
    UpdatePaymentManageScreen,
    AddPaymentManageScreen,
    CardFailureScreen,
    MobileSubscriptionRedirectScreen,
    CertCaptureScreen,
    ErrorScreen,
    PayNowScreen,
    OneTimePaymentScreen,
}