import React, { useState } from "react";
import _, { set } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { customized } from "../config";
import { ModalLayout, ModalBody, ModalHeader, ModalFooter } from '@strapi/design-system/ModalLayout';
import { Typography } from '@strapi/design-system/Typography';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import { Alert } from '@strapi/design-system/Alert';
import { Stack } from '@strapi/design-system/Stack';
import { Flex } from '@strapi/design-system/Flex';
import { ExclamationMarkCircle } from '@strapi/icons';
import { Dialog, DialogBody, DialogFooter } from '@strapi/design-system/Dialog';
import { Checkbox } from '@strapi/design-system/Checkbox';
import { Box } from '@strapi/design-system/Box';
import { Textarea } from '@strapi/design-system/Textarea';
import { Status } from '@strapi/design-system/Status';
import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import useDeviceTypes from "../hooks/useDeviceTypes";
import Strings from "../constants/Strings";
import { asyncTimeout, scrollTop } from "../utils/helpers";
import { TextInput } from "@strapi/design-system/TextInput";
import ScreenNames from "../constants/ScreenNames";

export default function CancelSubscriptionModal(props) {
    const history = useHistory();
    const { isMobileTablet } = useDeviceTypes();
    const { user, setSubscription } = useUser();
    const { cancelSubscription, submitRetentionOffer, submitCancelSurvey, fetchSubscriptions, updateSubscription } = useAPI();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: Strings.subscriptionSuccessfullyCancelled, link: '', linkText: '' });
    const [isVisible, setIsVisible] = useState(props.isVisible);
    const [subscription] = useState(props.subscription);
    const [link] = useState(props.link);

    const [cancelSteps, setCancelSteps] = useState(() => {
        if(customized.cancelSubscriptionSurvey.active) return 'cancel_survey'
        else return 'cancel';
    });
    const [acceptOffer, setAcceptOffer] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [reasonOther, setReasonOther] = useState('');
    const [reasonForm, setReasonForm] = useState({});
    const [reasonFormErrors, setReasonFormErrors] = useState([]);

    const [surveyStatusBox, setSurveyStatusBox] = useState({ variant: 'danger', active: false })

    const reset = () => {
        setCancelSteps(() => {
            if(customized.cancelSubscriptionSurvey.active) return 'cancel_survey'
            else return 'cancel';
        });
        setReasonForm({});
        setSelectedReasons([]);
        setReasonFormErrors([]);
        setSurveyStatusBox({...surveyStatusBox, active: false });
    }

    const updateSelectedReasons = (value) => {
        const found = _.find(selectedReasons, value);
        let selected = [...selectedReasons, value];
        if(!found) setSelectedReasons(selected);
        else {
            selected = _.filter(selectedReasons, v => v !== found);
            setSelectedReasons(selected);
        }

        console.log({...reasonForm, reasons: selected, value });        
        setReasonForm({...reasonForm, reasons: selected });
    }

    const submitSurvey = async() => {
        if(customized.subscriptionRetentionOffer.active) {
            setLoading(true);
            //Find required fields
            const required = [];
            _.map(reasonForm.reasons, i => _.map(i.require, term => required.push(term)));

            const errors = [];
            _.map(required, i => {
                if(!reasonForm[i]) errors.push(`${i === 'CancelSurvey:description' ? _.capitalize('Tell us why you are leaving us') : _.capitalize(i)} field is required`);
            });

            if(_.size(reasonForm.reasons) === 0) {
                errors.push(`At least one reason for canceling is required`);
            }

            setReasonFormErrors(errors);
            console.log({required, errors})

            if(_.size(errors) > 0) {
                setSurveyStatusBox({...surveyStatusBox, active: true });
                setLoading(false);
                return false;
            }

            setSurveyStatusBox({...surveyStatusBox, active: false });

            const subscriptions = await fetchSubscriptions( subscription.id );
            // Get the subscription with id = subscription.id
            const _subscription = _.find(subscriptions, i => i.id === subscription.id);
            console.log(_subscription)
            setSubscription(_subscription);

            if(_subscription.status === 'active') setCancelSteps('cancel')
            // else setCancelSteps('retention_offer');

            setLoading(false);
        }
        else setCancelSteps('cancel');
    }

    const acceptRetentionOffer = async() => {
        try {
            setLoading(true);
            setLoading(false);
            setIsVisible(prev => !prev);
            scrollTop();

            history.push({
                pathname: ScreenNames.RetentionOffer,
                state: { retentionProduct: process.env.REACT_APP_RETENTION_PRODUCT,
                    retentionBilling: process.env.REACT_APP_RETENTION_BILLING_ID
                }
            });
        } catch (error) {
            console.log({ error });
            setLoading(false);
            //Alert
            setShowAlert({
                active: true,
                variant: 'danger',
                body: customized.subscriptionRetentionOffer.alert.error.text,
                link: customized.subscriptionRetentionOffer.alert.error.link,
                linkText: customized.subscriptionRetentionOffer.alert.error.linkText
            });
            await asyncTimeout(5000);
            setShowAlert({...showAlert, active: false });
        }
    }

    const submit = async() => {
        try {   

            setLoading(true);

            const { reasons } = reasonForm;
            const keyValues = {};
            _.map(reasons, i => keyValues[i.id] = true);
            delete reasonForm.reasons;
            const metadata = {
                ...subscription?.metadata,
                ...reasonForm,
                ...keyValues
            };
            const newMeta = [];
            _.map(metadata, (value, key) => newMeta.push({ key, value }));

            const surveyBody = {
                metadata: newMeta
            }
            
            await updateSubscription(subscription.id, surveyBody);
            await cancelSubscription(subscription.id);
            
            /* Track a event */
            // analytics.track('subscriptionCancelled', {
            //     product: subscription.items.data[0].product.id,
            // })

            setIsVisible(prev => !prev);
            setLoading(false);

            scrollTop();            
            
            //Alert
            setShowAlert({ active: true, variant: 'success', body: Strings.subscriptionSuccessfullyCancelled });
            await asyncTimeout(2000);
            setShowAlert({...showAlert, active: false });
            setSubscription({});
            window.location.href = ScreenNames.Billing;
        } catch (error) {
            console.log({ error });
            setLoading(false);

            //Alert
            setShowAlert({ active: true, variant: 'danger', body: Strings.subscriptionFailedToCancel });
            await asyncTimeout(5000);
            setShowAlert({...showAlert, active: false });
        }
    }

    return <>

        {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} action={showAlert.link && <Link to={showAlert.link}>{showAlert.linkText || 'See more'}</Link>} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

        {link ? <Link to='#' onClick={() => {setIsVisible(prev => !prev); reset(); }} children={Strings.cancelSubscription} /> : 
        <Button onClick={() => {setIsVisible(prev => !prev); reset(); }} children={Strings.cancelSubscription} /> }

        {
            isVisible && 
            <>
                {
                    cancelSteps === 'cancel_survey' && <ModalLayout style={{ width: isMobileTablet ? '95vw' : 700 }} onClose={() => setIsVisible(prev => !prev)} labelledBy={customized.cancelSubscriptionSurvey.title}>
                        <ModalHeader>
                            <Typography variant='beta' fontWeight="bold" textColor="neutral800" as="h2" id={customized.cancelSubscriptionSurvey.title}>
                                {customized.cancelSubscriptionSurvey.title}
                            </Typography>
                        </ModalHeader>
                        <ModalBody>
                            <Typography variant="delta" id={customized.cancelSubscriptionSurvey.title}>
                                {customized.cancelSubscriptionSurvey.subtitle}
                            </Typography>
                            {surveyStatusBox.active && <Box style={{ marginTop: 15 }}>
                                <Status variant={surveyStatusBox.variant}>
                                    {_.map(reasonFormErrors, error => <Typography children={error} />)}
                                </Status>
                            </Box>}
                            <Flex direction="column" alignItems={'left'} style={{ marginTop: 15, wordBreak: 'keep-all'}}>
                                {_.map(customized.cancelSubscriptionSurvey.reasons, reason => <Box style={{ marginTop: 10 }}>
                                    <Checkbox
                                        style={{ borderRadius: 0 }}
                                        key={reason.id}
                                        checked={_.some(selectedReasons, i => i.id === reason.id)}
                                        onChange={() => updateSelectedReasons(reason)}
                                        children={<Typography children={reason.text} />} 
                                    />
                                </Box> )}
                                {_.map(customized.cancelSubscriptionSurvey.fields, field => <>
                                    {
                                        (field.active && field.type === 'textarea') && <Box style={{ marginTop: 20 }}>
                                            <Textarea
                                                name={field.name}
                                                // placeholder={field.placeholder}
                                                label={field.label}
                                                onChange={e => setReasonForm({...reasonForm, [field.name]: e.target.value })}
                                            >{reasonForm[field.name]}</Textarea>
                                        </Box>
                                    }
                                    {
                                       (field.active && field.type === 'textfield') && <Box style={{ marginTop: 20 }}>
                                            <TextInput
                                                name={field.name}
                                                // placeholder={field.placeholder}
                                                label={field.label}
                                                onChange={e => setReasonForm({...reasonForm, [field.name]: e.target.value })}
                                                value={reasonForm[field.name]}
                                            />
                                        </Box>
                                    }
                                </>)}
                            </Flex>
                        </ModalBody>
                        <ModalFooter 
                        startActions={<Button onClick={() => setIsVisible(prev => !prev)} variant="tertiary">{Strings.cancel}</Button>} 
                        endActions={<Button loading={loading} disabled={loading} onClick={submitSurvey}>{Strings.submit}</Button>} />
                    </ModalLayout> 
                }
                {
                    cancelSteps === 'retention_offer' && <ModalLayout style={{ width: isMobileTablet ? '95vw' : 500 }} onClose={() => setIsVisible(prev => !prev)} labelledBy={customized.subscriptionRetentionOffer.title}>
                        <ModalHeader>
                            <Typography variant='beta' fontWeight="bold" textColor="neutral800" as="h2" id={customized.subscriptionRetentionOffer.title}>
                                {customized.subscriptionRetentionOffer.title}
                            </Typography>
                        </ModalHeader>
                        <ModalBody>
                            <Typography variant="delta" id={customized.subscriptionRetentionOffer.title}>
                                {customized.subscriptionRetentionOffer.subtitle}
                            </Typography>
                            <Flex style={{ marginTop: 20 }} justifyContent='flex-start'>
                                <Typography variant='delta' textColor='success500' children={customized.subscriptionRetentionOffer.thirdtitle} />
                            </Flex>
                        </ModalBody>
                        <ModalFooter 
                        startActions={<Button onClick={() => setCancelSteps('cancel')} variant="tertiary">{customized.subscriptionRetentionOffer.decline.text}</Button>} 
                        endActions={<Button loading={loading} disabled={loading} onClick={acceptRetentionOffer}>{customized.subscriptionRetentionOffer.accept.text}</Button>} />
                    </ModalLayout> 
                }
                {
                    cancelSteps === 'cancel' && <Dialog onClose={() => setIsVisible(prev => !prev)} title={Strings.cancelSubscription} isOpen={isVisible}>
                        <DialogBody icon={<ExclamationMarkCircle />}>
                        <Stack size={2}>
                            <Flex justifyContent="center">
                                <Typography id="confirm-description">{Strings.areYouSureToCancelSubscription}</Typography>
                            </Flex>
                        </Stack>
                        </DialogBody>
                        <DialogFooter startAction={<Button onClick={() => setIsVisible(prev => !prev)} variant="tertiary">
                            {Strings.cancel}
                        </Button>} endAction={<Button variant="danger-light" loading={loading} disabled={loading} onClick={submit}>
                            {Strings.confirm}
                        </Button>} />
                    </Dialog>
                }
            </>
        }

            


    </>;
}