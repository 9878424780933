import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardSubtitle, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import { Image } from 'react-native';
import { Box } from '@strapi/design-system/Box';
import PaymentMethodModal from "./PaymentMethodModal";

import useUser from "../hooks/useUser";
import useDeviceTypes from "../hooks/useDeviceTypes";
import Strings from "../constants/Strings";
import { styleSettings } from "../config";

import useAPI from "../hooks/useAPI";
import ScreenNames from "../constants/ScreenNames";

import DDALogo from '../assets/images/payment-icons/check2.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';

export default function PaymentMethodBox(props){

    const history = useHistory();
    const { isMobileTablet } = useDeviceTypes();
    const { user, subscription } = useUser();
    const { fetchPaymentMethods } = useAPI();

    const [loading, setLoading] = useState(true);

    const [paymentMethod, setPaymentMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [loadingError, setLoadingError] = useState(false);
    const [updatePaymentPath, setUpdatePaymentPath] = useState(ScreenNames.UpdatePayment);
    const [addPaymentPath, setAddPaymentPath] = useState(ScreenNames.AddPayment);
    
    const fetchApi = async() => {
        try {
            setLoading(true);
            const paymentMethods = await fetchPaymentMethods();

            // let paymentMethods = [];
            // _.map(user?.payment_methods?.data, i => {
            //     if (i.type === 'CREDIT') {
            //         paymentMethods.push(i);
            //         if (i.primary) {
            //             setPaymentMethod(i);
            //         }
            //     }
            // });

            setPaymentMethods(paymentMethods);
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            console.log({ error });
            setLoadingError(true);
            setLoading(false);  
        }
    }

    const getLogo = (paymentMethod) => {
        // console.log('getLogo - Input:', { paymentMethod });
        
        const brand = _.toLower(_.get(paymentMethod, 'brand'));
        
        if (_.isUndefined(brand)) return DefaultLogo;
    
        // console.log('getLogo - "brand":', { paymentMethod, brand });
        
        switch (brand) {
          case 'amex':
            return AmexLogo;
          case 'discover':
            return DiscoverLogo;
          case 'mastercard':
            return MastercardLogo;
          case 'visa':
            return VisaLogo;
          case 'electroniccheck':
            return DDALogo;
          default:
            return DefaultLogo;
        }
    };

    useEffect(() => {
        (async() => {
            await fetchApi();
        })();
    },[]);  

    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{Strings.paymentMethods}</CardTitle>
                </CardContent>
            </CardBody>
            <Divider style={{ marginBottom: 2 }} />
            {loading && 
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Loader small />
                        <Typography children={`${Strings.loading} ${Strings.paymentMethod}`} style={{ marginTop: 10 }} />
                    </Flex>
                </>
            }

            {!loading && paymentMethods[0]?.id && 
                <>
                    {_.map(paymentMethods, pm =>(
                        <CardBody>
                            {
                                _.get(pm, 'category') === 'CREDIT' ?
                                <CardContent>
                                    <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.creditCard}</CardTitle>
                                    <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                        <Flex style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                            <img src={getLogo(pm)} style={{ width: 35, height: 35, marginRight: 10 }} />
                                            <div>ending in {_.get(pm, 'last')}</div>
                                        </Flex>
                                    </CardSubtitle>
                                    <Link 
                                        children={'Update'}
                                        onClick={() => history.push(_.replace(ScreenNames.UpdatePayment, ':id', pm.id), { paymentMethod: pm })}
                                        disabled={false}
                                    />
                                </CardContent> :
                                <CardContent>
                                    <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.bankAch}</CardTitle>
                                    <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                        <Flex style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                            <img src={getLogo(pm)} style={{ width: 35, height: 35, marginRight: 10 }} />
                                            <div>ending in {_.get(pm, 'last')}</div>
                                        </Flex>
                                    </CardSubtitle>
                                    {/* <Link 
                                        children={'Update'}
                                        // to={`${updatePaymentPath}?id=${_.get(pm, 'id')}`}
                                        onClick={() => history.push(_.replace(ScreenNames.UpdatePayment, ':id', pm.id), { paymentMethod: pm })}
                                        disabled={false}
                                    /> */}
                                </CardContent>
                            }
                        </CardBody>
                    ))}
                </>
            }

            {!loading && !paymentMethods[0]?.id && !loadingError && 
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`No ${Strings.paymentMethod} Available`} style={{ marginTop: 10 }} />
                </Flex>
            }

            {loadingError &&
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`${Strings.unableToLoad} ${Strings.paymentMethod}`} style={{ marginTop: 10 }} />
                </Flex>
            }
            
            <Divider />
            <CardBody>
                {!loadingError && <Button
                    style={{ height: 'auto' }}
                    children={'Add Payment Method'}
                    fullWidth
                    onClick={() => history.push(ScreenNames.AddPayment)}
                    disabled={false}/>
                }
                {/* {!loadingError && <PaymentMethodModal fullWidth disabled={loading} onComplete={fetchApi} />} */}
                {loadingError && <Button children={Strings.refresh} fullWidth disabled={loading} onClick={fetchApi} />}
            </CardBody>
            
        </Card>
    )
}