import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import PaymentBox from "../../components/PaymentBox";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { Link } from '@strapi/design-system/Link';
import { Grid, GridItem, Loader } from '@strapi/design-system';
import RebarPaymentForm from "../../components/RebarPaymentForm";
import { branding } from "../../config";
import useAPI from "../../hooks/useAPI";
import { currencyDisplay } from "../../utils/helpers";
import { Check, CheckCircle, Cross, CrossCircle } from "@strapi/icons";

export default function OneTimePaymentScreen(props) {

    const history = useHistory(); 
    const { isMobileTablet } = useDeviceTypes();
    const { token } = useUser();
    const [product, setProduct] = useState(null); // [product, setProduct]
    const { fetchProduct, oneTimePayment } = useAPI();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    // Get params from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type') || 'onetime'; // onetime, authorization, future
    const amount = queryParams.get('amount');
    const productId = queryParams.get('product');
    const firstName = queryParams.get('firstName');
    const lastName = queryParams.get('lastName');
    const address1 = queryParams.get('address1');
    const address2 = queryParams.get('address2');
    const redirectUri = queryParams.get('redirectUri');
    const city = queryParams.get('city');
    const state = queryParams.get('state');
    const zip = queryParams.get('zip');
    const email = queryParams.get('email');

    // Props for the PaymentForm component
    const paymentFormProps = {
      addressOn: true, // Show or hide address fields
      titlesOn: true, // Show or hide titles
      labelsOn: false, // Show or hide labels
      firstName: firstName || '', // Prefill first name if known
      lastName: lastName || '', // Prefill last name if known
      address1: address1 || '', // Prefill address1 if known
      address2: address2 || '', // Prefill address2 if known
      city: city || '', // Prefill city if known
      state: state || '', // Prefill state if known
      zip: zip || '', // Prefill zip if known
      buttonText: 'Pay Now', // Text for the submit button
      createPM: false,
      
      // Callback function to handle success
      onSuccess: async(e) => {
        console.log('Success', e);
        // history.goBack();
        await submitPayment( e );
      },
      
      // Callback function to handle failure
      onFailure: (e) => {
        console.log('Failure', e);
      },
    };

    // if product and amount, amount should be gn

    const submitPayment = async( paymentMethod ) => {
      try {
        const payload = {
          productId,
          paymentMethod,
          email,
        };

        if ( amount ) payload.amount = amount;
        console.log({ payload });

        const response = await oneTimePayment( payload );
        console.log({ response });
        setPaymentSuccess(true);
      } catch (error) {
        console.log(error);
        alert('There was an error submitting your payment. Please try again.');
      }
    };

    const getProduct = async() => {
      try {
        setLoading(true);
        const product = await fetchProduct(productId);
        if (product.status !== 'active') history.push(ScreenNames.Home);
        setProduct(product);
        console.log({ product });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if(!productId && !amount) history.push(ScreenNames.Home);
      getProduct();
    },[]);

    return (
      <Flex direction="column" alignItems='center'>        
        <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : '95vw', marginTop: 30 }}>
          <Grid gap={[5, 2, 1]}>
            {!paymentSuccess && <GridItem padding={1} col={6} s={12}>
              <Flex direction='column' alignItems='left'>
                <img style={{ width: 150, marginBottom: 20 }} src={branding.logo.light} />
                {!loading && <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{_.get(product, 'description', 'Product')}</Typography>}
                {!loading && <Typography variant={styleSettings.pageHeaders.variant}>{currencyDisplay(_.get(product, 'prices.0.amount', amount))}</Typography>}
                {loading && <Loader />}
              </Flex>
            </GridItem>}
            {!paymentSuccess && <GridItem padding={1} col={6} s={12}>
              <RebarPaymentForm {...paymentFormProps} />
            </GridItem>}
            {paymentSuccess && <GridItem col={12}>
              <Flex direction='column'>
                <Typography variant="alpha" textColor="primary500" style={{ textAlign: 'center'}}>
                  <CheckCircle />
                </Typography>
                <Typography variant="alpha" textColor="success500" style={{ textAlign: 'center'}}>
                  Payment Successful
                </Typography>
                <Typography variant="omega" style={{ textAlign: 'center'}}>
                  Thank you for your payment!
                </Typography>
              </Flex>
            </GridItem>}
          </Grid>
        </Flex>
      </Flex>
    )
}