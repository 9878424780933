import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex, Typography, Grid, GridItem, Stack } from '@strapi/design-system';
import { Loader } from '@strapi/design-system/Loader';
import SubscriptionBox from "../../components/SubscriptionBox";
import PaymentMethodBox from "../../components/PaymentMethodBox";
import AccountBox from "../../components/AccountBox";
import BillingHistoryBox from "../../components/BillingHistoryBox";
import useUser from "../../hooks/useUser";
import useAPI from "../../hooks/useAPI";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";

export default function BillingScreen(props) {

    const { isWidth } = useDeviceTypes();
    const { fetchBillingHistory } = useAPI();
    const history = useHistory();
    const { token, user, subscription } = useUser();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async() => {

            // const res = await fetchBillingHistory({ account: user.id, order: 'desc' });
            // if((_.size(res.data) === 0) && !subscription?.id) return history.push(ScreenNames.Subscriptions);
            setLoading(false);
        })();
    },[]);

    if(!token) return history.push(ScreenNames.SignIn);
    else return (
        <Flex direction='row' alignItems="center" justifyContent="center">
            {
                loading ? 
                <Flex alignItems='center' justifyContent='center'><Loader children={'Loading'} /></Flex>
                :
                <Flex alignItems="left" direction="column">
                    <Typography variant={styleSettings.pageHeader}>{Strings.billing}</Typography>
                    <Grid gap={[5, 2, 1]} style={{ width: isWidth, marginTop: 20 }}>
                        <GridItem padding={0} col={4} s={12}>
                            <Stack spacing={3} padding={0}>
                                <SubscriptionBox />
                                <PaymentMethodBox />
                                <AccountBox />
                            </Stack>
                        </GridItem>
                        <GridItem padding={0} col={8} s={12}>
                            <Stack spacing={3} padding={0}>                            
                                <BillingHistoryBox />
                            </Stack>
                        </GridItem>
                    </Grid>
                </Flex>
            }
            
        </Flex>
    )
}