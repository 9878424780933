const _ = require('lodash');

const config = {
  appName: 'Rebar Express Storefront',
  contact: {
    name: 'Rebar Technology',
    email: 'contact@rebartechnology.com',
    phone: '(800) 555-6666',
    address: '100 Corporate Woods, Suite 100, Rochester, NY 14623',
    website: 'https://rebartechnology.com/',
    termsUrl: 'https://rebartechnology.com/terms-of-use',
    privacyUrl: 'https://rebartechnology.com/privacy-policy',
    successRedirectUrl: 'https://www.rebartechnology.com',
  },
  branding:{
    themes: {
      light: {
          colors: {
            alternative100: '#f6ecfc',
            alternative200: '#e0c1f4',
            alternative500: '#ac73e6',
            alternative600: '#9736e8',
            alternative700: '#8312d1',
            buttonNeutral0: '#ffffff',
            buttonPrimary500: '#E90C54',
            buttonPrimary600: '#D70A4E',
            danger100: '#fcecea',
            danger200: '#f5c0b8',
            danger500: '#ee5e52',
            danger600: '#d02b20',
            danger700: '#b72b1a',
            neutral0: '#ffffff',
            neutral100: '#f6f6f9',
            neutral1000: '#181826',
            neutral150: '#eaeaef',
            neutral200: '#dcdce4',
            neutral300: '#c0c0cf',
            neutral400: '#a5a5ba',
            neutral500: '#8e8ea9',
            neutral600: '#666687',
            neutral700: '#4a4a6a',
            neutral800: '#32324d',
            "primary100": "#FF467E",
            "primary200": "#FF2A68",
            "primary500": "#E90C54",
            "primary600": "#D70A4E",
            "primary700": "#C40848",
            "secondary100": "#4B5D7E",
            "secondary200": "#374965",
            "secondary500": "#192B58",
            "secondary600": "#13234B",
            "secondary700": "#0C1A3E",
            success100: '#eafbe7',
            success200: '#c6f0c2',
            success500: '#5cb176',
            success600: '#328048',
            success700: '#2f6846',
            warning100: '#fdf4dc',
            warning200: '#fae7b9',
            warning500: '#f29d41',
            warning600: '#d9822f',
            warning700: '#be5d01',
        },
        shadows: {
            filterShadow: '0px 1px 4px rgba(33, 33, 52, 0.1)',
            focus: 'inset 2px 0px 0px rgb(39, 31, 224), inset 0px 2px 0px rgb(39, 31, 224), inset -2px 0px 0px rgb(39, 31, 224), inset 0px -2px 0px rgb(39, 31, 224)',
            focusShadow: '0px 0px 6px rgba(76, 191, 255, 0.75)',
            popupShadow: '0px 2px 15px rgba(33, 33, 52, 0.1)',
            tableShadow: '0px 1px 4px rgba(33, 33, 52, 0.1)',
        },
        sizes: {
            combobox: `${50 / 16}rem`,
            input: {
                S: `${32 / 16}rem`,
                M: `${40 / 16}rem`,
                L: `${50 / 16}rem`,
            },
            accordions: {
                S: `${48 / 16}rem`,
                M: `${88 / 16}rem`,
            },
        },
        zIndices: [
            5,
            10,
            15,
            20
        ],
        spaces: [
            "0px",
            "4px",
            "8px",
            "12px",
            "16px",
            "20px",
            "24px",
            "32px",
            "40px",
            "48px",
            "56px",
            "64px"
        ],
        //borderRadius: "4px",
        borderRadius: "5px",
        mediaQueries: {
            tablet: `@media (max-width: ${1100 / 16}rem)`,
            mobile: `@media (max-width: ${550 / 16}rem)`,
        },
        // fontSizes: [`${11 / 16}rem`, `${12 / 16}rem`, `${14 / 16}rem`, '1rem', `${18 / 16}rem`, `${32 / 16}rem`], //original
        fontSizes: ['12px', '16px','16px', '18px', '18px', '30px'],
        lineHeights: [
            1.14,
            1.22,
            1.25,
            1.33,
            1.43,
            1.45,
            1.5
        ],
        fontWeights: {
            regular: 400,
            semiBold: 500,
            bold: 600
        }
      },
      dark: {
        colors: {
            alternative100: '#181826',
            alternative200: '#4a4a6a',
            alternative500: '#ac73e6',
            alternative600: '#ac73e6',
            alternative700: '#e0c1f4',
            buttonNeutral0: '#ffffff',
            buttonPrimary500: '#348cad',
            buttonPrimary600: '#177CA1',
            danger100: '#181826',
            danger200: '#4a4a6a',
            danger500: '#ee5e52',
            danger600: '#ee5e52',
            danger700: '#ee5e52',
            neutral0: '#121A27',
            neutral100: '#090E17',
            neutral1000: '#ffffff',
            neutral150: '#222A38',
            neutral200: '#292929', // input border
            neutral300: '#A0A8B6',
            neutral400: '#a5a5ba',
            neutral500: '#c0c0cf',
            neutral600: '#d3d3d3',
            neutral700: '#eaeaef',
            neutral800: '#ffffff',
            neutral900: '#ffffff',
            primary100: '#3D4554',
            primary200: '#A4AAB6',
            primary500: '#66b7f1',
            primary600: '#7CD6F7',
            primary700: '#fff',
            secondary100: '#181826',
            secondary200: '#4a4a6a',
            secondary500: '#66b7f1',
            secondary600: '#66b7f1',
            secondary700: '#b8e1ff',
            success100: '#181826',
            success200: '#4a4a6a',
            success500: '#348cad', // 5cb176
            success600: '#348cad', // 5cb176
            success700: '#c6f0c2',
            warning100: '#181826',
            warning200: '#4a4a6a',
            warning500: '#f29d41',
            warning600: '#f29d41',
            warning700: '#fae7b9',
        },
        shadows: {
            filterShadow: '1px 1px 10px rgba(3, 3, 5, 0.35)',
            focus: 'inset 2px 0px 0px rgb(39, 31, 224), inset 0px 2px 0px rgb(39, 31, 224), inset -2px 0px 0px rgb(39, 31, 224), inset 0px -2px 0px rgb(39, 31, 224)',
            focusShadow: '0px 0px 6px rgba(76, 191, 255, 0.75)',
            popupShadow: '1px 1px 10px rgba(3, 3, 5, 0.35)',
            tableShadow: '1px 1px 10px rgba(3, 3, 5, 0.2)',
        },
        sizes: {
            combobox: `44px`,
            input: {
                S: `${32 / 16}rem`,
                M: `${40 / 16}rem`,
                L: `${47 / 16}rem`,
            },
            accordions: {
                S: `${48 / 16}rem`,
                M: `${88 / 16}rem`,
            },
        },
        zIndices: [
            5,
            10,
            15,
            20
        ],
        spaces: [
            "0px",
            "4px",
            "8px",
            "12px",
            "16px",
            "20px",
            "24px",
            "32px",
            "40px",
            "48px",
            "56px",
            "64px"
        ],
        borderRadius: "5px",
        mediaQueries: {
            tablet: `@media (max-width: ${1100 / 16}rem)`,
            mobile: `@media (max-width: ${550 / 16}rem)`,
        },
        // fontSizes: [`${11 / 16}rem`, `${12 / 16}rem`, `${14 / 16}rem`, '1rem', `${18 / 16}rem`, `${32 / 16}rem`], //original
        //fontSizes: [`${11 / 20}rem`, `${12 / 20}rem`, `${14 / 20}rem`, '1rem', `${18 / 20}rem`, `${32 / 20}rem`],
        fontSizes: ['12px', '16px','16px', '18px', '18px', '30px'],
        lineHeights: [
            1.14,
            1.22,
            1.25,
            1.33,
            1.43,
            1.45,
            1.5
        ],
        fontWeights: {
          regular: 400,
          semiBold: 500,
          bold: 600
        }
      }
    }
  },
  appString: {
    selectBasePackage: `Select a Subscription`,
    packagingByFeaturesTitle: `Packages`,
    packagingByFeaturesSubTitle: `Products Packaging by Features`,
    packagingFeatures: `Features Included:`,
    packagingAddOnOptions: `Add-On Options:`,

    //General
    submit: 'Submit',
    next: 'Next',
    selectPackage: 'Select Package',
    selectingPackage: 'Selecting Package',    
    monthlySubscription: 'Monthly Subscription',
    annualSubscription: 'Annual Subscription',
  },
  styleSettings: {
    pageHeader: 'alpha',
    pageSubHeader: 'beta',
    pageHeaders: {
        variant: 'alpha',
    },
    pageSubHeaders: {
        variant: 'epsilon',
        textColor: 'neutral300',
    },
    packagingByFeatures: {
        card: {
            minHeight: 900,
            width: 350
        }
    },
    backgroundColor: "neutral100",
    cards: {
        shadow: 'none',
        borderColor: 'neutral100',
        selectedBorderColor: 'primary600',
        button: {
            size: 'S',
            variant: 'default',
        }
    },
    modals: {
        title: {
            fontWeight: "bold",
            textColor: "neutral800",
            variant: "epsilon"
        },
        body: {
            background: "neutral100",
        },
        buttons: {
            startAction: {
                variant: "tertiary",
                size: 'S'
            },
            endAction: {
                variant: "default",
                size: 'S'
            },
        }
    },
    contentBox: {
        headerVariant: 'beta',
        lineHeaderVariant: 'omega',
        lineSubHeaderVariant: 'omega',
    },
    table: {
        thText: {
            variant: 'sigma'
        }
    },
    screenSizes: {
        mobile: '95vw',
        tablet: '95vw',
        small: '90vw',
        large: '80vw'
    },
  },
  authTypes: {
    SIGN_IN: 'sign_in',
    CREATE_ACCOUNT: 'create_account',
    FORGOT_PASSWORD: 'forgot_password',
  },
  currencyFormat: {
    locale: 'en-us',
    format: { 
        style: 'currency', 
        currency: 'USD', 
        // maximumSignificantDigits: 3 
    }
  },
  customized: {
    defaultTheme: 'dark',
    mangeBillingAccountExternally: true, 
    stepperActive: true,
    stepperType: 'visualdx',
    cancelSubscriptionSurvey: {
        active: true,
        title: 'Cancel Survey',
        subtitle: 'Before you cancel, please let us know why. Every bit of feedback helps us improve!',
        reasons: [
            {
                id: 'CancelSurvey:not_useful',
                text: 'Not useful.',
            },
            {
                id: 'CancelSurvey:need_more_information_accuracy',
                text: 'I need more information about the accuracy of the result.',
            },
            {
                id: 'CancelSurvey:difficult_to_use',
                text: 'Too difficult to use.',
            },
            {
                id: 'CancelSurvey:access_through_institution',
                text: 'I can get access through my institution',
            },
            {
                id: 'CancelSurvey:need_more_pricing_options',
                text: 'I need more flexible pricing options.',
            },
            {
                id: 'CancelSurvey:does_not_justify_price',
                text: `I don't use my subscription enough to justify the price.`,
            },
            {
                id: 'CancelSurvey:other',
                text: 'Other',
                require: ['CancelSurvey:description']
            },
        ],
        fields: [
            {
                id: "CancelSurvey:description",
                type: 'textarea', // textarea or textfield
                name: "CancelSurvey:description",
                active: true,
                label: 'Tell us why you are leaving us',
                placeholder: 'Tell us why you are leaving us',
                error: 'A reason why is required',
                fieldName: '"Tell us why you are leaving us"'
            },
        ],
        submit: {
            text: 'Submit'
        }
    },
    subscriptionRetentionOffer: {
        active: true,
        title: 'Rebar Demo Special Promotion',
        subtitle: `We'd love you to stick around. Take advantage of this reduced rate and keep access.`,
        thirdtitle: `Subscription for one year at $99.99 USD`,
        discount: {
            id: '',
            text: ''
        },
        decline: {
            text: `No thanks`,
        },
        accept: {
            text: 'Claim Discount'
        },
        alert: {
            success: {
                text: 'Thanks for staying with us!',
                link: '',
                linkText: '',
            },
            error: {
                text: `Sorry, we could not process your request.`,
                link: 'https://google.com',
                linkText: 'Learn more',
            }
        }
    },
    subscribeSteps: [
      {
        step: [
            {
                id: 'account',
                step: 'Account',
                status: 'active',
            },
            {
                id: 'Payment',
                step: 'Payment',
                status: 'empty',
            },
            {
                id: 'profile',
                step: 'Profile',
                status: 'empty',
            }
        ]
      },
      {
          step: [
              {
                  id: 'account',
                  step: 'Account',
                  status: 'complete',
              },
              {
                  id: 'Payment',
                  step: 'Payment',
                  status: 'active',
              },
              {
                  id: 'profile',
                  step: 'Profile',
                  status: 'empty',
              }
          ]
      },
      {
          step: [
              {
                  id: 'account',
                  step: 'Account',
                  status: 'complete',
              },
              {
                  id: 'payment',
                  step: 'Payment',
                  status: 'active',
              },
              {
                  id: 'profile',
                  step: 'Profile',
                  status: 'empty',
              }
          ]
      },
      {
          step: [
              {
                  id: 'account',
                  step: 'Account',
                  status: 'complete',
              },
              {
                  id: 'payment',
                  step: 'Payment',
                  status: 'complete',
              },
              {
                  id: 'profile',
                  step: 'Profile',
                  status: 'empty',
              }
          ]
      }
    ],
    stepNumberByScreen: {
        subscriptions: 1,
        payment: 1,
        paymentConfirmation: 2,
        paymentSuccess: 3,
    },
    hidePaymentMethodsCard: true,
    secondPurchaseConfirmationCheckbox: {
        visible: true,
        text: 'The cost of medication, lab testing, and video doctor consults are not included with program costs. You further authorize rebardemo/NextMed to automatically charge your card $138 every four weeks according to our Membership Agreement when one month has passed since sign-up. Your first month with NextMed is free.'
    }
  },
  variables: {
    cognito: {
        dev: {
            domain: 'https://rebardemo-auth-dev.auth.us-east-2.amazoncognito.com',
            userPoolId: 'us-east-2_wKVeAKird',
            redirectUri: 'https://d3oc1dbb9evff9.cloudfront.net',
            redirectFailureUri: 'https://d3oc1dbb9evff9.cloudfront.net',
            clientId: '6rntivbgejm2g981dtq37982c7'
        },
        stg: {
            domain: 'https://rebardemo-auth-stg.auth.us-east-2.amazoncognito.com',
            userPoolId: 'us-east-2_Ebvm8wW0w',
            redirectUri: 'https://d2jgfck89aprue.cloudfront.net',
            redirectFailureUri: 'https://d2jgfck89aprue.cloudfront.net',
            clientId: '4t6vbmvku0mhaom19uc1d3q4cm'
        },
        prd: {
            domain: 'https://rebardemo-auth-prd.auth.us-east-2.amazoncognito.com',
            userPoolId: 'us-east-2_P4Mb0UIaZ',
            redirectUri: 'https://d2sux0t4wnnst4.cloudfront.net',
            redirectFailureUri: 'https://d2sux0t4wnnst4.cloudfront.net',
            clientId: '5cmamdo72s1726lhspsn2o4v8p'
        },
    },
    storefrontApi: {
        dev: {
            url: 'https://mbvr1yfbya.execute-api.us-east-2.amazonaws.com/dev/api',
        },
        stg: {
            url: 'https://badlseoqj1.execute-api.us-east-2.amazonaws.com/stg/api',
        },
        prd: {
            url: 'https://fzzj8oi9y2.execute-api.us-east-2.amazonaws.com/dev/api',
        },
    },
    vault: {
      dev: {
        url: 'https://vault.dev.rebartechnology.io',
        key: _.get( process.env, 'REACT_APP_VAULT_KEY', 'NaU8b3AMT49GbsTJjggdgE85V4vzczvAX8vEkwJVefuJc9Y7GW5eak2R8RBQD9zgJ2ZXEJzMmecHDN52VQqWarfX' ),
      },
      stg: {
        url: 'https://vault.stg.rebartechnology.io',
        key: _.get( process.env, 'REACT_APP_VAULT_KEY', '8Lc7zSZn7BqvTtdPjc3s37DvE35SExss2QzpstwpHT2vpqU6eqLfY5f4YRF9dc9fudeHaqhaV4ZmjjkZHgn7zMNx' ),
      },
      prd: {
        url: 'https://vault.rebartechnology.io',
        key: _.get( process.env, 'REACT_APP_VAULT_KEY', '8Lc7zSZn7BqvTtdPjc3s37DvE35SExss2QzpstwpHT2vpqU6eqLfY5f4YRF9dc9fudeHaqhaV4ZmjjkZHgn7zMNx' ),
      },
    },
    api: {
      dev: {
        url: _.get( process.env, 'REACT_APP_API_URL', 'https://dev.api.demo.rebartechnology.io' ),
        key: _.get( process.env, 'REACT_APP_API_KEY', 'xyornq1zz' ),
      },
      stg: {
        url: _.get( process.env, 'REACT_APP_API_URL', 'https://stg.api.demo.rebartechnology.io' ),
        key: _.get( process.env, 'REACT_APP_API_KEY', '3IQRrhfsy' ),
      },
      prd: {
        url: _.get( process.env, 'REACT_APP_API_URL', 'https://api.demo.rebartechnology.io' ),
        key: _.get( process.env, 'REACT_APP_API_KEY', 'Z8WuLwspc' ),
      },
    },
    cashbox: {
      dev: {
        url: 'https://api.staging.us-west.vindicia.com',
        cacheUrl: 'https://cache.extension.staging.us-west.vindicia.com',
        username: 'visualdx_soap',
        password: '',
        security: ''
      },
      stg: {
        url: 'https://api.staging.us-west.vindicia.com',
        cacheUrl: 'https://cache.extension.staging.us-west.vindicia.com',
        username: '',
        password: '',
        security: ''
      },
      prd: {
        url: 'https://api.vindicia.com',
        cacheUrl: 'https://cache.extension.us-west.vindicia.com',
        username: _.get( process.env, 'REACT_APP_CASHBOX_USERNAME', '' ),
        password: _.get( process.env, 'REACT_APP_CASHBOX_PASSWORD', '' ),
        security: _.get( process.env, 'REACT_APP_CASHBOX_PASSWORD', '' )
      }
    },
    certCapture: {
      dev: {
        url: 'https://api.certcapture.com/v2/',
        token: _.get( process.env, 'REACT_APP_CERTCAPTURE_TOKEN', '' ),
        clientId: _.get( process.env, 'REACT_APP_CERTCAPTURE_CLIENTID', '' ),
      },
      stg: {
        url: 'https://api.certcapture.com/v2/',
        token: _.get( process.env, 'REACT_APP_CERTCAPTURE_TOKEN', '' ),
        clientId: _.get( process.env, 'REACT_APP_CERTCAPTURE_CLIENTID', '' ),
      },
      prd: {
        url: 'https://api.certcapture.com/v2/',
        token: _.get( process.env, 'REACT_APP_CERTCAPTURE_TOKEN', '' ),
        clientId: _.get( process.env, 'REACT_APP_CERTCAPTURE_CLIENTID', '' ),
      }
    },
    slackWebhookCredentials: {
      dev: {
        creds: _.get( process.env, 'SLACK_WEBHOOK_CREDENTIALS', '' )
      },
      stg: {
        creds: _.get( process.env, 'SLACK_WEBHOOK_CREDENTIALS', '' )
      },
      prd: {
        creds: _.get( process.env, 'SLACK_WEBHOOK_CREDENTIALS', '' )
      }
    },
    jwt: {
      dev: {
        secret: '523adca4d72346e297378937cd8bf9ec',
        keyUrl: _.get( process.env, 'REACT_APP_JWT_KEY_URL', '' ),
        alg: _.get( process.env, 'REACT_APP_JWT_KEY_URL', 'RS384' )
      },
      stg: {
        secret: '523adca4d72346e297378937cd8bf9ec',
        keyUrl: _.get( process.env, 'REACT_APP_JWT_KEY_URL', '' ),
        alg: _.get( process.env, 'REACT_APP_JWT_KEY_URL', 'RS384' )
      },
      prd: {
        secret: _.get( process.env, 'JWT_SECRET', '523adca4d72346e297378937cd8bf9ec' ),
        keyUrl: _.get( process.env, 'REACT_APP_JWT_KEY_URL', '' ),
        alg: _.get( process.env, 'REACT_APP_JWT_KEY_URL', 'RS384' )
      }
    },
  }
}

export default config;