import { currencyFormat } from "../config";

export function asyncTimeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function currencyDisplay(amount) {
    return new Intl.NumberFormat(currencyFormat.locale, currencyFormat.format).format(amount);
}

export function scrollTop() {
    return window.scrollTo({top: 0, behavior: 'smooth'});
}