import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import AuthBox from "../../components/AuthBox";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import useUser from "../../hooks/useUser";

export default function SignInScreen(props) {
    const history = useHistory();
    const { isWidth } = useDeviceTypes();
    const { token, subscription } = useUser();

    return (
        <Flex direction="column">
            <Flex justifyContent='center' alignItems='center' style={{ width: isWidth }} padding={8}>
                <AuthBox />
            </Flex>            
        </Flex>
    )
}