import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from "react";
import _ from 'lodash';
import { Image } from "react-native";
import { Alert } from '@strapi/design-system/Alert';
import { useHistory } from "react-router-dom";
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from "@strapi/design-system/Typography";
import { Button } from "@strapi/design-system/Button";
import { TextInput } from "@strapi/design-system/TextInput";
import { Combobox, ComboboxOption } from '@strapi/design-system/Combobox';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { currencyDisplay } from "../utils/helpers";
import {
    Card,
    CardBody,
    CardContent,
    CardTitle,
    CardSubtitle,
} from '@strapi/design-system/Card';
import states from '../utils/states.json';
import countries from '../utils/countries.json';
import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";
import useScript from "../hooks/useScript";
import useTheme from "../hooks/useTheme";
import Strings from "../constants/Strings";
import { styleSettings } from "../config";
import { apiAccounts } from "../services";
import { HmacSHA256 } from "crypto-js";
import encHex from "crypto-js/enc-hex";
import ScreenNames from "../constants/ScreenNames";

import DDALogo from '../assets/images/payment-icons/dda.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';

const schema = Yup.object().shape({
    vin_account_holder: Yup.string().required('Card holder is required.'),
    vin_billing_address_line1: Yup.string().required('Billing address is required.'),
    vin_billing_address_line2: Yup.string(),
    vin_billing_address_city: Yup.string().required('City is required.'),
    vin_billing_address_district: Yup.string().required('State is required.'),
    vin_billing_address_postal_code: Yup.string().required('Zip code is required.'),
    vin_billing_address_country: Yup.string().required('Country required.'),
    // vin_credit_card_cvn: Yup.string().required('CVV Required'),
    // vin_credit_card_expiration_date: Yup.string().required('Expiration Required'),
});
const schemaUpdate = Yup.object().shape({
    vin_account_holder: Yup.string().required('Card holder is required.'),
    vin_billing_address_line1: Yup.string().required('Billing address is required.'),
    vin_billing_address_line2: Yup.string(),
    vin_billing_address_city: Yup.string().required('City is required.'),
    vin_billing_address_district: Yup.string().required('State is required.'),
    vin_billing_address_postal_code: Yup.string().required('Zip code is required.'),
    vin_billing_address_country: Yup.string().required('Country required.'),
    // vin_credit_card_cvn: Yup.string().required('CVV Required'),
    vin_credit_card_expiration_date: Yup.string().required('Expiration Required').matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Must match format MM/YY").min(5, 'Expiration date invalid').max(5, 'Expirationd date invalid'),
});

const PaymentMethodForm = forwardRef((props, ref) => {

    const history = useHistory();
    const { updatePaymentMethod, fetchAccount, updateAccount, googleAddressAutocomplete } = useAPI();

    const queryParams = new URLSearchParams(window.location.search);
    const cardId = queryParams.get('id');

    const { theme } = useTheme();    

    const [vindicia, setVindicia] = useState('');

    const formRef = useRef(null);

    const { user, setUser, cart, getUser } = useUser();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [selected, setSelected] = useState();
    const [state, setStateValue] = useState('');
    const [sessionId, setSessionid] = useState('');
    const [sessionHash, setSessionHashId] = useState('');
    const [prevPaymentMethod, setPrevPaymentMethod] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [onStateFocus, setOnStateFocus] = useState(false);
    const [onCountryFocus, setOnCountryFocus] = useState(false);


    const [showAlert, setShowAlert] = useState({ active: false,
        variant: 'success', body: Strings.paymentMethodUpdatedSuccessfully });

    useImperativeHandle(ref, () => ({

        async submit() {
            try {
                setLoading(true);

                //formRef.current.submit();                
                
            } catch (error) {
                console.log({ error });
                setLoading(false);
                props.onError(error);

            }
        }
    
    }));
    
    useEffect(() => {
        setInterval(function() {
            if(window?.vindicia && !vindicia) setVindicia(() => window.vindicia);
        },300);
    },[]);

    const [line1, setLine1] = useState(null);

    const handlePlaceSelect = (e, setFieldValue) => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            {
                types: ["address"],
                fields: [
                  "address_components",
                  "formatted_address",
                  "geometry",
                  "name",
                ],
            }
        );

        autocomplete.addListener("place_changed", () => {
            const selectedPlace = autocomplete.getPlace();

            // Extract the address components
            const addressComponents = selectedPlace.address_components.reduce(
                (acc, component) => {
                const types = component.types;
                types.forEach((type) => {
                    if (type === "administrative_area_level_1") {
                    acc.state = component.short_name;
                    } else if (type === "country") {
                    acc.country = component.short_name;
                    } else if (type === "locality") {
                    acc.city = component.long_name;
                    } else if (type === "postal_code") {
                    acc.zip = component.long_name;
                    } else if (type === "street_number") {
                    acc.streetNumber = component.long_name;
                    } else if (type === "route") {
                    acc.streetName = component.long_name;
                    }
                });
                return acc;
                },
                {}
            );

            // Update the state with the extracted components
            setFieldValue('vin_billing_address_line1',`${addressComponents.streetNumber} ${addressComponents.streetName}`);
            setFieldValue('vin_billing_address_district',addressComponents.state);
            setFieldValue('vin_billing_address_country',addressComponents.country);
            setFieldValue('vin_billing_address_postal_code',addressComponents.zip);
            setFieldValue('vin_billing_address_city',addressComponents.city);

            setLine1(`${addressComponents.streetNumber} ${addressComponents.streetName}`);

        });
    };

    const generateSessionId = async() => {
        const hmac_key = process.env.REACT_APP_HMAC;        
        const session_id = `CC${Math.floor(Math.random() * 1000000000)}`;
        const session =  `${session_id}#POST#/payment_methods`;
        const session_hash = encHex.stringify((HmacSHA256(session, hmac_key)));
        setSessionid(session_id);
        setSessionHashId(session_hash);
    }

    const vindiciaAuthId = btoa(`${process.env.REACT_APP_PMT_USERNAME}:${process.env.REACT_APP_PMT_PASSWORD}`);

    useScript(`https://cdn2.vindicia.com/pmt/vindicia.js`);
    useEffect(() => {
        (async() => {

            if(cardId) {
                const found = _.find(user?.payment_methods?.data, i => i.id === cardId );
                // If card found in user payment methods then update this card only.
                if(found) {
                    setIsUpdate(true);
                    console.log('Selected PM:', found)
                    setPrevPaymentMethod(found);
                }
                //if(_.isEmpty(found)) history.goBack();
            }
            
            // If vindicia loads and this form is not an update card form
            if(vindicia && !props?.update) {
                await generateSessionId();
                vindicia.setup({
                    formId: 'mainForm', // dom element of form
                    vindiciaServer: "cdn2.vindicia.com",
                    vindiciaRestServer: process.env.REACT_APP_VINDICIA_SERVER, 
                    vindiciaAuthId,
                    hostedFields: {
                        cardNumber: {
                            selector: "#vin_credit_card_account",
                            //placeholder: "Enter Credit Card Number", // optional field
                        },
                        expirationDate: {
                            selector: "#vin_credit_card_expiration_date",
                            placeholder: "MM/YY", // optional field
                            format: "MM/YY", // optional, default is MM/YY.
                            formatinput: true
                        },      
                        cvn: {
                            selector: "#vin_credit_card_cvn",
                            //placeholder: "Enter CVV" // optional field
                        },
                        styles: {
                            "input": {
                                "width": "100%",
                                "font-family": "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                "font-size": "16px",
                                "color": theme === 'dark' ? 'white' : 'white',
                                "height": "47px",
                                "padding": "6px 12px",
                                "line-height": "1.42857",
                                "border-radius": "8px",
                                "border": "1px solid #292929",
                            },
                            ":focus": { // optional
                                "border": "3px solid #7CD6F7",
                                "outline": "0"
                            },
                            ".valid": { // optional
                                "outline": "0",
                            },
                            // ".notValid": { // optional
                            //     "border": "2px solid #ee5e52",
                            //     "outline": "0"
                            // },
                            // custom width
                            // "@media (min-width: 600px) and (max-width: 800px)": {
                            //     "input": {
                            //         "font-size": "16pt"
                            //     }
                            // }
                        },
                    },
                    onSubmitEvent: async function(data){
                        console.log('onSubmitEvent >> ', data);
                    },
                    onSubmitCompleteEvent: async function(data){
                        console.log('onSubmitCompleteEvent >> ', data);
            
                        const accountData = await apiAccounts.get(user.id);
                        const account = accountData.data.data;
                        const payment_methods = account?.payment_methods?.data || [];
                        const PaymentMethods = [{ vid: data.detail.vid, primary: true }];

                        try {
                            const updatedAccount = await updateAccount(account.id, {
                                ...account,
                                payment_methods: PaymentMethods,
                            });
                            const _accountData = await apiAccounts.get(user.id);
                            const _account = _accountData.data.data;
                            setUser(_account);
                        } catch (error) {

                            if(error?.response?.data?.data?.message === `Failed to save account: 'Valid CreditCard PaymentMethod test failed: This Credit Card is already in use by another customer object of class Entity::MerchantCustomer tried to save subobject of class Entity::PaymentMethod'`)
                                setShowAlert({...showAlert, active: true, variant: 'danger', body: 'This Credit Card is already in use by another customer.' });
                            else
                                setShowAlert({...showAlert, active: true, variant: 'danger', body: 'An error occured adding this Credit Card to your account. Try again.' });

                            window.scrollTo(0, 0);
                            vindicia.clearData();
                            vindicia.resetCompleteStatus();
                            generateSessionId();
                            setLoading(false);
                            return false;
                        }

                        vindicia.clearData();
                        vindicia.resetCompleteStatus();
                        setLoading(false);
                        props.onComplete();
                        return true;
                    },
                    onSubmitCompleteFailedEvent: async function(data){

                        //alert('Payment Method created failed - error : ' + data.detail.status);
                        setShowAlert({...showAlert, active: true, variant: 'danger', body: data?.detail?.error || data.detail.status });

                        //Get Account data
                        const res = await fetchAccount(user.id);

                        let body = {
                            ...res,
                        };

                        if(body?.metadata) {
                            if(body?.metadata?.cardFailures) {
                                let num = parseInt(body.metadata.cardFailures);
                                body.metadata.cardFailures = num + 1;

                            } else {
                                body.metadata = {
                                    cardFailures: 1
                                }
                            }

                        } else {
                            body.metadata = {
                                cardFailures: 1
                            }
                        }

                        //Update Account data
                        const updatedAccount = await updateAccount(user.id, body);
                        
                        setUser(updatedAccount);
                        window.scrollTo(0, 0);
                        generateSessionId();
                        setLoading(false);
                        return true;
                    },
                    onVindiciaFieldEvent: function(event){
                        // console.log('onVindiciaFieldEvent', event);
                    },
                });
            }
            
            
        })();
        
    },[vindicia]);    

    const myFunction = async(values) => {
        //If update, run update payment method api
        if(getUser()?.metadata?.cardFailures && (parseInt(getUser()?.metadata?.cardFailures) >= 3)) return history.push(ScreenNames.CardFailure);
        if(props?.update) {
            setLoading(true);
            const body = {
                id: cardId,
                account_holder: values.vin_account_holder,
                credit_card: {
                    expiration_date: `20${values.vin_credit_card_expiration_date.slice(3,5)}${values.vin_credit_card_expiration_date.slice(0, 2)}`
                },
                billing_address: {
                    vid: prevPaymentMethod.vid,
                    line1: values.vin_billing_address_line1,
                    line2: values.vin_billing_address_line2,
                    city: values.vin_billing_address_city,
                    district: values.vin_billing_address_district,
                    postal_code: values.vin_billing_address_postal_code,
                    country: values.vin_billing_address_country
                },
            };
            
            try {
                await updatePaymentMethod(cardId, body);
                props.onComplete();
                setLoading(false);
            } catch (error) {
                switch (error?.response?.status) {
                    case 400:
                        setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Failed to update the credit card' });
                        break;
                    case 402:
                        setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Failed to update the credit card' });
                        break;
                
                    default:
                        setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Failed to update the credit card' });
                        break;
                }
                setLoading(false);
            }

            return false;
        }
    
        if(vindicia.isLoaded()) {
            if(merchantValidate()) vindicia.submit();
        }   
    }

    function merchantValidate() {
        setLoading(true);
        if (!vindicia.isValid('vin_credit_card_account'))
        {
            // sensitive field
            // alert('Please enter a valid credit card before submitting');
            vindicia.frames.cardNumber.source.focus();
            setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Please enter a valid credit card before submitting' });
            setLoading(false);
            return false;
        }
        if (!vindicia.isValid('vin_credit_card_expiration_date'))
        {
            // sensitive field
            // alert('Please enter a valid expiration Date before submitting');
            vindicia.frames.expirationDate.source.focus();
            setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Please enter a valid expiration Date before submitting' });
            setLoading(false);
            return false;
        }
        if (!vindicia.isValid('vin_credit_card_cvn'))
        {
            // sensitive field
            // alert('Please enter a cvv before submitting');
            vindicia.frames.cvn.source.focus();
            setShowAlert({...showAlert, active: true, variant: 'danger', body: 'Please enter a cvv before submitting' });
            setLoading(false);
            return false;
        }
        if (vindicia.cardType == "amex" && vindicia.dataLength('vin_credit_card_cvn') != 4)
        {
            // alert("American Express CVV must be 4 digits");
            setShowAlert({...showAlert, active: true, variant: 'danger', body: 'American Express CVV must be 4 digits' });
            setLoading(false);
            return false;
        }

        setShowAlert({...showAlert, active: false, variant: 'success', body: Strings.paymentMethodUpdatedSuccessfully });
        
        return true;
    }

    const convertTermText = ({ quantity, type }) => {
        if(quantity === 1) {
            return _.toLower(`per ${type}`);
        } else {
            return _.toLower(`every ${quantity} ${type}s`);
        }
    }
    
    if(props?.update) return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Flex direction='column' style={{ width: '100%', marginBottom: 20 }}>                

                <Formik
                    enableReinitialize
                    initialValues={{
                        vin_account_holder: prevPaymentMethod?.account_holder || '',
                        vin_billing_address_line1: prevPaymentMethod?.billing_address?.line1 || '',
                        vin_billing_address_line2: prevPaymentMethod?.billing_address?.line2 || '',
                        vin_billing_address_city: prevPaymentMethod?.billing_address?.city || '',
                        vin_billing_address_district: prevPaymentMethod?.billing_address?.district || '',
                        vin_billing_address_postal_code: prevPaymentMethod?.billing_address?.postal_code || '',
                        vin_billing_address_country: prevPaymentMethod?.billing_address?.country || '',
                        vin_credit_card_expiration_date: `${prevPaymentMethod?.credit_card?.expiration_date.slice(4, 6)}/${prevPaymentMethod?.credit_card?.expiration_date.slice(2, 4)}` || ''
                    }}
                    onSubmit={myFunction}
                    validationSchema={schemaUpdate}
                    validateOnChange={false}
                    >

                        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                            <Form id='mainForm' name='mainForm' onSubmit={handleSubmit} method="post">
                                <Grid gap={{
                                    desktop: 5,
                                    tablet: 5,
                                    mobile: 5
                                }} style={{ width: '100%' }}>
                                    <GridItem col={6} s={6}>
                                        <Typography variant='beta'>Card ending in {_.get(prevPaymentMethod?.credit_card, 'last_digits', 'XXXX')}</Typography>
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <TextInput 
                                            id="vin_account_holder" 
                                            name="vin_account_holder" 
                                            label="Cardholder Name" 
                                            placeholder="Cardholder Name"
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_account_holder} 
                                            onChange={handleChange}
                                            value={values.vin_account_holder}
                                        />
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <TextInput 
                                            id="vin_credit_card_expiration_date" 
                                            name="vin_credit_card_expiration_date" 
                                            label="Card Expiration" 
                                            placeholder="MM/YY"
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_credit_card_expiration_date} 
                                            onChange={handleChange}
                                            value={values.vin_credit_card_expiration_date}
                                        />
                                    </GridItem>
                                    <GridItem col={12} s={12} style={{ flexDirection: 'column' }}>
                                        <Typography variant="pi" fontWeight="bold" children={'Billing Address'} />
                                        <input
                                        id="autocomplete"
                                        name="vin_billing_address_line1"
                                        type="text"
                                        placeholder=""
                                        value={values.vin_billing_address_line1}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handlePlaceSelect(e, setFieldValue);                                            
                                        }}
                                        style={{
                                            width: '100%',
                                            fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                            fontSize: '16px',
                                            color: theme === 'dark' ? 'white' : 'white',
                                            height: "47px",
                                            padding: "6px 12px",
                                            lineHeight: "1.42857",
                                            borderRadius: "8px",
                                            border: "1px solid #292929",
                                            background: '#121A27',
                                            borderRadius: '8px',
                                            marginTop: '5px'
                                        }}
                                        />
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <TextInput 
                                            id="vin_billing_address_line2" 
                                            name="vin_billing_address_line2" 
                                            label="Billing Address Line 2" 
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_line2} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_line2}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <TextInput 
                                            id="vin_billing_address_city" 
                                            name="vin_billing_address_city" 
                                            label="City" 
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_city} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_city}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <Flex alignItems='flex-start' direction='column'>
                                            <Typography variant="pi" fontWeight="bold" children={'State'} style={{ marginBottom: 4}} />
                                            <select
                                                name="vin_billing_address_district"
                                                onChange={handleChange}
                                                value={values.vin_billing_address_district}
                                                onFocus={() => setOnStateFocus(true)}
                                                onBlur={() => setOnStateFocus(false)}
                                                style={{
                                                    height: 47,
                                                    width: '100%',
                                                    fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                    fontSize: 16,
                                                    color: theme === 'dark' ? 'white' : 'white',
                                                    padding:"6px 12px",
                                                    lineHeight: 1.42857,
                                                    borderRadius: 8,
                                                    border: onStateFocus ? '3px solid #7CD6F7' : '1px solid #292929',
                                                    background: '#121A27',
                                                    outline: onStateFocus && 0
                                                    
                                                }}
                                            >
                                                <option value={''}>{}</option>
                                                <option value={'International'}>{'International'}</option>
                                                {_.map(states, i => <option key={i.abbreviation} value={i.abbreviation}>{i.name}</option>)}
                                            </select>
                                            {errors.vin_billing_address_district && <Typography textColor='danger500' variant="pi" children={errors.vin_billing_address_district} style={{ marginTop: 4}} />}
                                        </Flex>
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <TextInput
                                            id="vin_billing_address_postal_code" 
                                            name="vin_billing_address_postal_code" 
                                            label="Zip" 
                                            disabled={loading}                                            
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_postal_code} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_postal_code}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <Flex alignItems='flex-start' direction='column'>
                                            <Typography variant="pi" fontWeight="bold" children={'Country'} style={{ marginBottom: 4}} />
                                            <select
                                                name="vin_billing_address_country"
                                                onChange={handleChange}
                                                value={values.vin_billing_address_country}
                                                onFocus={() => setOnCountryFocus(true)}
                                                onBlur={() => setOnCountryFocus(false)}
                                                style={{
                                                    height: 47,
                                                    width: '100%',
                                                    fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                    fontSize: 16,
                                                    color: theme === 'dark' ? 'white' : 'white',
                                                    padding:"6px 12px",
                                                    lineHeight: 1.42857,
                                                    borderRadius: 8,
                                                    border: onCountryFocus ? '3px solid #7CD6F7' : '1px solid #292929',
                                                    background: '#121A27',
                                                    outline: onCountryFocus && 0
                                                    
                                                }}
                                            >
                                                <option value={''}>{}</option>
                                                {_.map(countries, i => <option value={i.code}>{i.name}</option>)}
                                            </select>
                                            {errors.vin_billing_address_country && <Typography textColor='danger500' variant="pi" children={errors.vin_billing_address_country} style={{ marginTop: 4}} />}
                                        </Flex>
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <Button type='submit' fullWidth loading={loading} disabled={loading} size='L' onClick={handleSubmit} style={{ marginTop: 15 }}>{'Save Payment Method'}</Button>
                                    </GridItem>
                                    
                                </Grid>
                            </Form>
                        )}
                </Formik>
                
                {/* <Divider /> */}
                {/* <Flex padding={4}>
                    <Typography variant='beta' children={'Select a different payment method'} />
                </Flex>
                <Flex padding={4} direction='row'>
                    <table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2" title="PayPal" onclick="javascript:window.open('https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/34_Yellow_PayPal_Pill_Button.png" alt="PayPal" /></a></td></tr></table>
                </Flex> */}
            </Flex>
        </>
    )
    else return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Flex direction='column' style={{ width: '100%', marginBottom: 20 }}>            
                <Formik
                    innerRef={formRef}           
                    enableReinitialize
                    initialValues={{
                        vin_account_holder: prevPaymentMethod?.account_holder || '',
                        vin_billing_address_line1: prevPaymentMethod?.billing_address?.line1 || '',
                        vin_billing_address_line2: prevPaymentMethod?.billing_address?.line2 || '',
                        vin_billing_address_city: prevPaymentMethod?.billing_address?.city || '',
                        vin_billing_address_district: prevPaymentMethod?.billing_address?.district || '',
                        vin_billing_address_postal_code: prevPaymentMethod?.billing_address?.postal_code || '',
                        vin_billing_address_country: prevPaymentMethod?.billing_address?.country || '',
                        vin_credit_card_expiration_date: `${prevPaymentMethod?.credit_card?.expiration_date.slice(4, 6)}/${prevPaymentMethod?.credit_card?.expiration_date.slice(2, 4)}` || ''
                    }}
                    onSubmit={myFunction}
                    validationSchema={schema}
                    validateOnChange={false}
                    >
                        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                            <Form id='mainForm' name='mainForm' onSubmit={handleSubmit} method="post">
                                <input name="vin_session_id" value={sessionId} type="hidden" />
                                <input name="vin_session_hash" value={sessionHash} type="hidden" />
                                <input name="vin_active" value={true} type="hidden" />
                                <input name="vin_validate" value="0" type="hidden" />
                                {/*  <input name="vin_ignore_avs_policy" value="1" type="hidden" />
                                <input name="vin_ignore_cvn_policy" value="1" type="hidden" /> */}

                                <Grid gap={{
                                    desktop: 5,
                                    tablet: 5,
                                    mobile: 5
                                }} style={{ width: '100%' }}>
                                    <GridItem col={12} s={12}>
                                        {cart[0]?.package && <Card background='primary400' style={{ width: '100%'}}>
                                            <CardBody>
                                                <CardContent>
                                                    <CardTitle><Typography variant='delta'>{cart[0]?.package?.package || cart[0]?.package.response.id}</Typography></CardTitle>
                                                    {
                                                        cart[0]?.package?.dry_response?.items?.data[0]?.campaign_id ? (
                                                            <>
                                                                <Flex direction='row'>
                                                                    <Typography variant='pi' style={{wordBreak: 'keep-all', textDecoration: 'line-through', marginRight: 5 }}>{`${currencyDisplay(cart[0]?.package?.dry_response?.items?.data[0]?.product?.prices?.data[0]?.amount)}`}</Typography> 
                                                                    <CardSubtitle><Typography>{currencyDisplay(cart[0]?.package?.price)}</Typography></CardSubtitle>
                                                                </Flex>
                                                                <Typography
                                                                textColor={'primary500'}
                                                                style={{ wordBreak: 'break-word', marginTop: 5, fontSize: 13, fontStyle:'italic' }}
                                                                variant="pi">
                                                                    { cart[0]?.package?.campaign_response?.flat_amount_discounts?.data && 
                                                                        `$${cart[0]?.package?.campaign_response?.flat_amount_discounts?.data[0]?.amount} off your 
                                                                        ${((cart[0]?.campaign_response?.number_of_periods === 1) || !cart[0]?.campaign_response?.number_of_periods) ? `first ${cart[0].package?.term}` : `first ${cart[0]?.package?.campaign_response?.number_of_periods} ${cart[0].package?.term}s`}. Full price when promotion ends.` 
                                                                    }

                                                                    { cart[0]?.package?.campaign_response?.percentage_discount?.data && 
                                                                        `${cart[0]?.package?.campaign_response?.percentage_discount?.data[0]?.amount}% off your 
                                                                        ${((cart[0]?.campaign_response?.number_of_periods === 1) || !cart[0]?.campaign_response?.number_of_periods) ? `first ${cart[0].package?.term}` : `first ${cart[0]?.package?.campaign_response?.number_of_periods} ${cart[0].package?.term}s`}. Full price when promotion ends.` 
                                                                    }
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <CardSubtitle><Typography>{currencyDisplay(cart[0]?.package?.price)} {convertTermText(cart[0]?.package?.response.default_billing_plan.periods.data[0])}</Typography></CardSubtitle>
                                                        )
                                                    }                                                    
                                                </CardContent>
                                            </CardBody>
                                        </Card>}
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <TextInput 
                                            id="vin_account_holder" 
                                            name="vin_account_holder" 
                                            label="Cardholder" 
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_account_holder} 
                                            onChange={handleChange}
                                            value={values.vin_account_holder}
                                        />
                                    </GridItem>                                   
                                    <GridItem col={12} s={12}>
                                        <Typography style={{ marginBottom: 2 }} variant="pi" fontWeight="bold" children={'Card Number'}/>
                                        <div class="field card" style={{ background: '#121A27', borderRadius: '8px', marginTop: '4px' }}>
                                            <div id="vin_credit_card_account"></div>
                                            <span class="card_icon"></span>
                                        </div>
                                        {/* <Flex direction='row' marginTop={2}>
                                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 1 }} source={VisaLogo} />
                                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 1 }} source={AmexLogo} />
                                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 1 }} source={DiscoverLogo} />
                                            <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 1 }} source={MastercardLogo} />
                                        </Flex> */}
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <Typography style={{ marginBottom: 2 }} variant="pi" fontWeight="bold" children={'Card Expiration'}/>
                                        <div style={{ background: '#121A27', borderRadius: '8px', marginTop: '4px' }} id="vin_credit_card_expiration_date"></div>
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <div id="cvn">
                                            <Typography variant="pi" fontWeight="bold" children={'CVV'} />
                                            {/* <a href="#" data-toggle="popover" title="Where is my CVV" data-content="">
                                            <i class="icon-question"></i></a> */}
                                            <div id="vin_credit_card_cvn" style={{ background: '#121A27', borderRadius: '8px', marginTop: '4px' }}></div>
                                        </div>
                                    </GridItem>
                                    <GridItem col={12} s={12} style={{ flexDirection: 'column' }}>
                                        <Typography variant="pi" fontWeight="bold" children={'Billing Address'} />
                                        <input
                                        id="autocomplete"
                                        name="vin_billing_address_line1"
                                        type="text"
                                        placeholder=""
                                        value={values.vin_billing_address_line1}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handlePlaceSelect(e, setFieldValue);                                            
                                        }}
                                        style={{
                                            width: '100%',
                                            fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                            fontSize: '16px',
                                            color: theme === 'dark' ? 'white' : 'white',
                                            height: "47px",
                                            padding: "6px 12px",
                                            lineHeight: "1.42857",
                                            borderRadius: "8px",
                                            border: "1px solid #292929",
                                            background: '#121A27',
                                            borderRadius: '8px',
                                            marginTop: '5px'
                                        }}
                                        />
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <TextInput 
                                            id="vin_billing_address_line2" 
                                            name="vin_billing_address_line2" 
                                            label="Billing Address Line 2" 
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_line2} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_line2}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <TextInput 
                                            id="vin_billing_address_city" 
                                            name="vin_billing_address_city" 
                                            label="City" 
                                            disabled={loading}
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_city} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_city}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <Flex alignItems='flex-start' direction='column'>
                                            <Typography variant="pi" fontWeight="bold" children={'State'} style={{ marginBottom: 4}} />
                                            <select
                                                name="vin_billing_address_district"
                                                onChange={handleChange}
                                                value={values.vin_billing_address_district}
                                                onFocus={() => setOnStateFocus(true)}
                                                onBlur={() => setOnStateFocus(false)}
                                                style={{
                                                    height: 47,
                                                    width: '100%',
                                                    fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                    fontSize: 16,
                                                    color: theme === 'dark' ? 'white' : 'white',
                                                    padding:"6px 12px",
                                                    lineHeight: 1.42857,
                                                    borderRadius: 8,
                                                    border: onStateFocus ? '3px solid #7CD6F7' : '1px solid #292929',
                                                    background: '#121A27',
                                                    outline: onStateFocus && 0
                                                    
                                                }}
                                            >
                                                <option value={''}>{}</option>
                                                <option value={'International'}>{'International'}</option>
                                                {_.map(states, i => <option key={i.abbreviation} value={i.abbreviation}>{i.name}</option>)}
                                            </select>
                                            {errors.vin_billing_address_district && <Typography textColor='danger500' variant="pi" children={errors.vin_billing_address_district} style={{ marginTop: 4}} />}
                                        </Flex>
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <TextInput
                                            id="vin_billing_address_postal_code" 
                                            name="vin_billing_address_postal_code" 
                                            label="Zip" 
                                            disabled={loading}                                            
                                            style={styleSettings.overrides.textInput}
                                            error={errors.vin_billing_address_postal_code} 
                                            onChange={handleChange}
                                            value={values.vin_billing_address_postal_code}
                                        />
                                    </GridItem>
                                    <GridItem col={6} s={6}>
                                        <Flex alignItems='flex-start' direction='column'>
                                            <Typography variant="pi" fontWeight="bold" children={'Country'} style={{ marginBottom: 4}} />
                                            <select
                                                name="vin_billing_address_country"
                                                onChange={handleChange}
                                                value={values.vin_billing_address_country}
                                                onFocus={() => setOnCountryFocus(true)}
                                                onBlur={() => setOnCountryFocus(false)}
                                                style={{
                                                    height: 47,
                                                    width: '100%',
                                                    fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
                                                    fontSize: 16,
                                                    color: theme === 'dark' ? 'white' : 'white',
                                                    padding:"6px 12px",
                                                    lineHeight: 1.42857,
                                                    borderRadius: 8,
                                                    border: onCountryFocus ? '3px solid #7CD6F7' : '1px solid #292929',
                                                    background: '#121A27',
                                                    outline: onCountryFocus && 0
                                                    
                                                }}
                                            >
                                                <option value={''}>{}</option>
                                                {_.map(countries, i => <option value={i.code}>{i.name}</option>)}
                                            </select>
                                            {errors.vin_billing_address_country && <Typography textColor='danger500' variant="pi" children={errors.vin_billing_address_country} style={{ marginTop: 4}} />}
                                        </Flex>
                                    </GridItem>
                                    <GridItem col={12} s={12}>
                                        <Button type='submit' fullWidth loading={loading} disabled={loading} size='L' onClick={handleSubmit} style={{ marginTop: 15 }}>{'Save Payment Method'}</Button>
                                    </GridItem>
                                    
                                </Grid>
                            </Form>
                        )}
                </Formik>
                
                {/* <Divider /> */}
                {/* <Flex padding={4}>
                    <Typography variant='beta' children={'Select a different payment method'} />
                </Flex>
                <Flex padding={4} direction='row'>
                    <table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2" title="PayPal" onclick="javascript:window.open('https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/34_Yellow_PayPal_Pill_Button.png" alt="PayPal" /></a></td></tr></table>
                </Flex> */}
            </Flex>
        </>
    )
});

export default PaymentMethodForm;