
const { default: clientConfig } = require('./clientConfig');
const demoMode = process.env.REACT_APP_DEMO_MODE;
const stage = process.env.REACT_APP_STOREFRONT_ENV;
const devMode = process.env.NODE_ENV !== 'production';

const client = clientConfig.contact.name;
const appName = clientConfig.appName;
const clientContact = {
    ...clientConfig.contact
};

const branding = {
    ...clientConfig.branding,
    logo: {
        light: require('../src/assets/logos/logo.png'),
        dark: require('../src/assets/logos/logo.png'),
    }
};
const appStrings = {
    selectBasePackage: `Select a Subscription`,
    packagingByFeaturesTitle: `Packages`,
    packagingByFeaturesSubTitle: `VisualDx Products Packaging by Features`,
    packagingFeatures: `Features Included:`,
    packagingAddOnOptions: `Add-On Options:`,

    //General
    submit: 'Submit',
    next: 'Next',
    selectPackage: 'Select Package',
    selectingPackage: 'Selecting Package',    
    monthlySubscription: 'Monthly Subscription',
    annualSubscription: 'Annual Subscription',


}

const overrides = {
    textInput: { 
        height: 44
    },
}

const styleSettings = {
    ...clientConfig.styleSettings,
    overrides,
}

const authTypes = {
    ...clientConfig.authTypes
}

const currencyFormat = {
    ...clientConfig.currencyFormat
}

//Activate APIs for test mode in /hooks/useAPI.js
const apiDevModes = {
    fetchProductPackages: false,
    fetchSubscription: false,
    fetchPaymentMethod: false,
    fetchAccount: false,
    fetchBillingHistory: false,
    cancelSubscription: false,
    purchaseSubscription: false,
    updateSubscription: false,
    updatePaymentMethod: false,
    signIn: false,
    createAccount: false,
    signOut: false,
    forgotPassword: false,
    resetPasswordWithCode: false,
    submitCancelSurvey: false,
    submitRetentionOffer: false,
}

const upgradeOnlyProducts = [
    {
        from: {
            id: 'VisualDx (Monthly)',
            vid: 'e633c1484756a98293a62e9befc6133c8a13200c'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Monthly)',
            vid: 'ff3ca4c18c8d0a0541eda184486bf8ae81915be6'
        },
    },
    {
        from: {
            id: 'VisualDx (Monthly)',
            vid: 'e633c1484756a98293a62e9befc6133c8a13200c'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Annual)',
            vid: '1b0a33bc40c725b567045d116b4cec43ef2fbb5f'
        },
    },
    {
        from: {
            id: 'VisualDx (Annual)',
            vid: 'eb64cd560529e751f76749f385734b4b190f521e'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Monthly)',
            vid: 'ff3ca4c18c8d0a0541eda184486bf8ae81915be6'
        },
    },
    {
        from: {
            id: 'TEST_2day_notrial9',
            vid: '9a730b3fa017cb3224d4ff36481cef068225eab4'
        },
        to: {
            id: 'TEST 2day notrial50',
            vid: 'e9ac2a017f83560ec5749426ec11fb1fa434f0d2'
        },
    },
    {
        from: {
            id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Core Monthly' : 'VisualDx Core Monthly',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '5a7e16dd8bbfa003caf83a884329e14d00fb5e89' : '3c59fde4e49a1fd9a37b17155e6e3fe836a12720',
        },
        to: {
            d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Elite Monthly' : 'VisualDx Elite Monthly',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '491d7636abff50e178eaee24c1e7ce5dc002fc8e' : 'eba8dd2a506c7f4d72964fe1dcdccf330b4c4351',
        },
    },
    {
        from: {
            id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Core Annual' : 'VisualDx Core Annual',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '6e858459dd66153242b24a6824ba84c4c32955ad' : 'ab1afec07833596edf96b541bcb7f865966c82c8',
        },
        to: {
            d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Elite Annual',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'aa9a4147ebaa193c283fc24032ec6623e1193753' : '8b8d6c3d01e5b62a21e7b1df31bd06304392f44c',
        },
    },
    // {
    //     from: {
    //         id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //         vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //     },
    //     to: {
    //         d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //         vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //     },
    // },
];

const specialEventOfferBillPlans = [
    {
        param: 'AADShow',
        product: stage === 'prd' ? 'VisualDx Plus DermExpert Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'ADDShow',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'AMWA',
        product: stage === 'prd' ? 'VisualDx Plus DermExpert Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'AMWA',
        billPlan: stage === 'prd' ? 'Annual 4-month-trial' : 'Test Annual 4-month-trial',
    }
];

const customized = {
    ...clientConfig.customized,
    upgradeOnlyProducts,
    specialEventOfferBillPlans,
}

export {
    client,
    appName,
    branding,
    appStrings,
    demoMode,
    devMode,
    styleSettings,
    currencyFormat,
    authTypes,
    customized,
    overrides,
    apiDevModes,
    clientContact,
}