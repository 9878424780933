import { 
    client,
    clientContact,
    appStrings
    
} from "../config";

export default {

    // Client can modify
    client,
    selectBasePackage: appStrings.selectBasePackage || `Select a Base Package`,
    packagingByFeaturesTitle: appStrings.packagingByFeaturesTitle || `Packages`,
    packagingByFeaturesSubTitle: appStrings.packagingByFeaturesSubTitle || `Packaging Features and Add-Ons`,
    packagingFeatures: appStrings.packagingFeatures || `Features Included`,
    packagingAddOnOptions: appStrings.packagingAddOnOptions || `Add-On Options`,
    submit: appStrings.submit || 'Submit',
    next: appStrings.next || 'Next',
    selectPackage: appStrings.selectPackage || 'Select Package',
    selectingPackage: appStrings.selectingPackage || 'Selecting Package',    
    monthlySubscription: appStrings.monthlySubscription || 'Monthly Subscription',
    annualSubscription: appStrings.annualSubscription || 'Annual Subscription',
    clientContact,
    

    //System Variables
    company: 'Company',
    demoMode: 'Demo Mode',
    devMode: 'Development Mode',
    noDataAvailable: 'No data available',
    subscription: 'Subscription',
    paymentMethod: 'Payment Method',
    paymentMethods: 'Payment Methods',
    billingHistory: 'Billing History',
    dueDate: 'Due Date',
    manage: 'Manage',
    change: 'Change',
    loading: 'Loading',
    nextBillDate: 'Next Bill Date',
    firstBillDate: 'First Bill Date',
    plan: 'Plan',
    product: 'Product',
    planIncludes: 'Plan Includes',
    addOns: 'Add Ons',
    billing: 'Manage Subscription',
    paymentMethod: 'Payment Method',
    creditCard: 'Credit Card',
    bankAch: 'Bank (ACH)',
    account: 'Account',
    name: 'Name',
    email: 'Email',
    forgotPassword: 'Forgot Password',
    resetPassword: 'Reset Password',
    unableToLoad: 'Unable to Load',
    refresh: 'Refresh',
    download: 'Download',
    payNow: 'Pay Now',
    payYourInvoice: 'Pay Your Invoice',
    cancelSubscription: 'Cancel Subscription',
    billingHistory: 'Billing History',
    description: 'Description',
    perMonth: 'per month',
    perYear: 'per year',
    annual: 'annual',
    annually:'annually',
    monthly: 'monthly',
    yearly: 'yearly',
    status: 'Status',
    package: 'Package',
    dueDate: 'Due Date',
    total: 'Total',
    amountDue: 'Amount Due',
    tax: 'Tax',
    invoice: 'Invoice',
    invoiceId: 'Invoice #',
    cancel: 'Cancel',
    areYouSureToCancelSubscription: `Are you sure you want to cancel this subscription?`,
    subscriptionSuccessfullyCancelled: `Your subscription has been canceled. You can continue to access your subscription features until the end of your current billing cycle.`,
    subscriptionFailedToCancel: 'Subscription Failed to Cancel',
    updatePaymentMethod: 'Update Payment Method',
    paymentMethodUpdatedSuccessfully: 'Payment Method Updated Successfully',
    confirmMySubscription: 'Confirm My Subscription',
    savePaymentInformation: 'Save Payment Information',
    subscriptionSummary: 'Subscription Summary',
    price: 'Price',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    createAccount: 'Create Account',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    code: 'Code',
    fullName: 'Full Name',
    name: 'Name',
    purchase: 'Purchase',
    manage: 'Manage',
    confirm: 'Confirm',
    termsAndConditions: 'Terms and Conditions',
    privacyPolicy: 'Privacy Policy',
    accept: 'Accept',
    decline: 'Decline',
    settings: 'Settings',
    myAccount: 'My Account',
    continue: 'Continue',
    account: 'Account',
    payment: 'Payment',
    profile: 'Profile',
    updatePaymentDetails: 'Update Payment Details',
    promo_code: 'Promo Code',
    addPaymentMethod: 'Add Payment Method',

    //Pages
    paymentScreen: {
        title: 'Enter Payment Details',
        subtitle: 'All fields are required',
    },
    paymentConfirmationScreen: {
        title: 'Confirm My Subscription'
    }
}