import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch as Routes,
  Route
} from "react-router-dom";
import { default as clientConfig } from './clientConfig';
import { NativeBaseProvider } from "native-base";
import { appName, styleSettings } from './config';
import { ThemeProvider } from '@strapi/design-system/ThemeProvider';
import { Box } from '@strapi/design-system/Box';
import useCachedResources from "./hooks/useCacheResources";
import Screens from "./constants/Screens";
import ScreenNames from "./constants/ScreenNames";
import useTheme from './hooks/useTheme';
import Header from './components/Header';
import useUser from './hooks/useUser';
import ErrorBoundary from './components/ErrorBoundary';

export default function App() {  

  const isLoadingComplete = useCachedResources();
  const { theme, lightTheme, darkTheme, setTheme } = useTheme();
  const { setRedirectUri } = useUser();
  
  const queryParams = new URLSearchParams(window.location.search);
  const redirect_uri = queryParams.get('auth_redirect_uri');

  useEffect(() => {
    document.title = clientConfig.appName;
    //Set page favicon
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = require('./assets/favicon.webp');
  },[]);

  useEffect(() => {
    //Save Params
    if (redirect_uri !== 'null' && redirect_uri !== null && redirect_uri !== 'undefined' && redirect_uri) setRedirectUri(redirect_uri);
  },[]);

  if(!isLoadingComplete){
    return <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <Box style={{ width: '100vw', height: '100vh'}} background={styleSettings.backgroundColor} />
    </ThemeProvider>
  }
  else
  return (    
    <Router style={{ backgroundColor: 'black'}}>   
      <NativeBaseProvider>
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
          <Header />
          <Box padding={5} background={styleSettings.backgroundColor} style={{ minHeight: '100vh' }}>
              <Routes>
                <Route exact path={ScreenNames.Home}>
                  <ErrorBoundary>
                    <Screens.VerifyScreen />
                  </ErrorBoundary>
                </Route>          
                <>
                  <Route exact path={ScreenNames.Subscriptions}>
                    <ErrorBoundary>
                      <Screens.SubscriptionsScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.Payment}>
                    <ErrorBoundary>
                      <Screens.PaymentScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.UpdatePayment}>
                    <ErrorBoundary>
                      <Screens.UpdatePaymentScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.UpdatePaymentManage}>
                    <ErrorBoundary>
                      <Screens.UpdatePaymentManageScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.AddPayment}>
                    <ErrorBoundary>
                      <Screens.AddPaymentScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.OneTimePayment}>
                    <ErrorBoundary>
                      <Screens.OneTimePaymentScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.AddPaymentManage}>
                    <ErrorBoundary>
                      <Screens.AddPaymentManageScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.Billing}>
                    <ErrorBoundary>
                      <Screens.BillingScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.CardFailure}>
                    <ErrorBoundary>
                      <Screens.CardFailureScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.MobileSubscriptionRedirect}>
                    <ErrorBoundary>
                      <Screens.MobileSubscriptionRedirectScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route exact path={ScreenNames.CertCapture}>
                    <ErrorBoundary>
                      <Screens.CertCaptureScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.PurchaseConfirmation}>
                    <ErrorBoundary>
                      <Screens.PurchaseConfirmationScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.RetentionOffer}>
                    <ErrorBoundary>
                      <Screens.RetentionOfferScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.Settings}>
                    <ErrorBoundary>
                      <Screens.SettingsScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.PayNow}>
                    <ErrorBoundary>
                      <Screens.PayNowScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.PurchaseSuccess}>
                    <ErrorBoundary>
                      <Screens.PurchaseSuccessScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.SignIn}>
                    <ErrorBoundary>
                      <Screens.SignInScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.Verify}>
                    <ErrorBoundary>
                      <Screens.VerifyScreen />
                    </ErrorBoundary>
                  </Route>
                  <Route path={ScreenNames.Error}>
                    <ErrorBoundary>
                      <Screens.ErrorScreen />
                    </ErrorBoundary>
                  </Route>
                  {/* <Route path='*'>
                    <Screens.SignInScreen />
                  </Route> */}
                </>
              </Routes>
          </Box>
        </ThemeProvider>
      </NativeBaseProvider>
    </Router>    
  );
}

