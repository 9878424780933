import _ from "lodash";
import { customized } from "../config";

export const convertProductPackages = (data, { category = 'storefront' }) => {
    const arry = [];
    if(category === null || category === 'undefined') category = 'storefront';

    _.map(data, item => {

        const entitlements = [];
        _.map(item?.entitlements?.data, entitlement => {
            entitlements.push({ ...entitlement, product: entitlement?.description, description: '', });
        });

        const obj = {
            id: item.vid,
            package: item?.metadata?.ProductName,
            term: _.lowerCase(item?.default_billing_plan?.periods?.data[0]?.type),
            price: item?.prices?.data[0]?.amount,
            products: entitlements,
            addOns: [],
            response: item,
        }
        
        if(item?.metadata?.category === category) arry.push(obj);
    });

    return arry;
}

export const convertSubscriptions = (data) => {
    
    const arry = [];
    _.map(data, item => {
        console.log({ item })
        const products = [];
        _.map(item?.subscriptionItems?.data, subItem => {
            products.push({ id: subItem?.product?.vid, product: subItem?.product?.id, description: subItem?.descriptions?.data[0].description, });
        });

        arry.push({
            id: item.id,
            nextBillDate: item?.next_billing?.created || item.entitled_through,
            package: item.description,
            price: item?.next_billing?.amount || 0,
            billingState: item.billing_state,
            products: products,
            addOns: [],
            response: item

        })
    });
    return arry;
}

export const convertTransactions = (data) => {
    const arry = [];

    _.map(data.data, transaction => {
        //Dont display if amount is 0
        if(transaction.amount === 0) return false;
        arry.push({
            "id": transaction.id,
            "vid": transaction.vid,
            "billDate": transaction.original_billing_date,
            "amount": transaction.amount,
            "name": transaction?.items?.data[1]?.sku
        });
    });

    data.data = arry;
    return data;
}

export const convertInvoices = (data) => {
    const arry = [];

    _.map(data.data, invoice => {
        arry.push({
            "id": invoice.id,
            "billDate": invoice.due_date,
            "amount": invoice.invoice_balance,
            "taxAmount": invoice.tax_charges_total,
            "total": invoice.invoice_balance + invoice.tax_charges_total,
            "status": _.lowerCase(invoice.status),
            "package": {
                "id": 1,
                "package": "Basic",
                "prices": {
                    "monthly": 9.99,
                    "yearly": 99
                },
                "products": [
                    {
                        "id": 1,
                        "product": "Mobile Access",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    }
                ],
                "addOns": [
                    {
                        "id": 2,
                        "product": "Web Access",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 3,
                        "product": "Patient Handouts",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 4,
                        "product": "Quiz",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 5,
                        "product": "DermExpert",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 6,
                        "product": "CME",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 7,
                        "product": "Videos",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    },
                    {
                        "id": 8,
                        "product": "Education Portal",
                        "description": "This is the product description meant to explain the product",
                        "prices": {
                            "price": 10,
                            "frequency": "per month"
                        }
                    }
                ]
            }        
        })
    });

    data.data = arry;
    return data;
}

// export const upgradeProduct = (prevProduct, newProduct) => {
//     //Check if from.vid = prevProduct.vid and to.vid = newProduct.vid, if matches then upgrade, else downgrade
//     const upgrades = customized.upgradeOnlyProducts;
//     const found = _.find(upgrades, item => (item.from.vid === prevProduct.vid) && (item.to.vid ===  newProduct.vid));
//     if(found) return true
//     else return false;
// }

export const upgradeProduct = (prevProduct, newProduct) => {
    if((prevProduct.metadata?.type === 'base') && (newProduct.metadata?.type === 'base') && (parseInt(newProduct.metadata?.rank || 0) > parseInt(prevProduct.metadata?.rank || 0))) return true
    else return false;
}

export const specialEventOfferBillPlan = ({param, product}) => {
    const items = customized.specialEventOfferBillPlans;
    console.log({ product, param, items })

    const found = _.find(items, item => (_.toLower(item.param) === _.toLower(param) && (item.product === product)));
    console.log({ found })
    if(!found) return false;
    else return found;
}