import React, { useEffect, useState } from "react";
import _ from 'lodash';
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { IconButton } from '@strapi/design-system/IconButton';
import { Badge } from '@strapi/design-system/Badge';
import { Tooltip } from '@strapi/design-system/Tooltip';
import FilePdf from '@strapi/icons/FilePdf';
import { Table, Thead, Tbody, Tr, Td, Th } from '@strapi/design-system/Table';
import { Dots, NextLink, PageLink, Pagination, PreviousLink } from '@strapi/design-system/Pagination';

import Strings from "../constants/Strings";
import { currencyDisplay } from "../utils/helpers";
import { styleSettings } from "../config";

import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";

import ScreenNames from "../constants/ScreenNames";

export default function BillingHistoryBox(props){

    const history = useHistory();

    const { user } = useUser();

    const { fetchTransactions, fetchInvoices } = useAPI();

    const [loading, setLoading] = useState(true);

    const [invoices, setInvoices] = useState([
        // {
        //     "id": "test6a13539d5-f167-49ab-adb2-dea4ed6341c237",
        //     "subscriptionId": null,
        //     "created": 1675297657144,
        //     "modified": 1675297668979,
        //     "status": "paid",
        //     "lineItems": [
        //         {
        //             "currency": "usd",
        //             "externalId": "test6",
        //             "taxes": [],
        //             "amount": "452",
        //             "quantity": "1",
        //             "productId": "b9887179-bb31-456b-9771-cab0a331299b",
        //             "productFeatures": "",
        //             "productBillingPlan": "",
        //             "productLogo": "",
        //             "productName": "15123ewe1",
        //             "sortOrder": 99,
        //             "sku": "15123ewe1",
        //             "productDescription": "gafwaefawef",
        //             "productType": "onetime"
        //         }
        //     ],
        //     "period": null,
        //     "total": 452,
        //     "totalTax": 0,
        //     "balance": 0,
        //     "externalId": "test6",
        //     "description": null,
        //     "number": 117,
        //     "confirmation": {
        //         "send": true,
        //         "email": "test@rebar.com",
        //         "attachment": null
        //     }
        // },
        // {
        //     "id": "test5a13539d5-f167-49ab-adb2-dea4ed6341c243",
        //     "subscriptionId": null,
        //     "created": 1675297603773,
        //     "modified": 1675297603773,
        //     "status": "due",
        //     "lineItems": [
        //         {
        //             "currency": "usd",
        //             "externalId": "test5",
        //             "taxes": [],
        //             "amount": "452",
        //             "quantity": "1",
        //             "productId": "b9887179-bb31-456b-9771-cab0a331299b",
        //             "productFeatures": "",
        //             "productBillingPlan": "",
        //             "productLogo": "",
        //             "productName": "15123ewe1",
        //             "sortOrder": 99,
        //             "sku": "15123ewe1",
        //             "productDescription": "gafwaefawef",
        //             "productType": "onetime"
        //         }
        //     ],
        //     "period": null,
        //     "total": 452,
        //     "totalTax": 0,
        //     "balance": 452,
        //     "externalId": "test5",
        //     "description": null,
        //     "number": 116,
        //     "confirmation": {
        //         "send": true,
        //         "email": "test@rebar.com",
        //         "attachment": null
        //     }
        // },
        // {
        //     "id": "test3a13539d5-f167-49ab-adb2-dea4ed6341c24",
        //     "subscriptionId": null,
        //     "created": 1675295464914,
        //     "modified": 1675295479755,
        //     "status": "paid",
        //     "lineItems": [
        //         {
        //             "currency": "usd",
        //             "externalId": "test3",
        //             "taxes": [],
        //             "amount": "333",
        //             "quantity": "1",
        //             "productId": "9b8bcbda-f184-468e-aa28-001896991bff",
        //             "productFeatures": "",
        //             "productBillingPlan": "",
        //             "productLogo": "",
        //             "productName": "111111",
        //             "sortOrder": 99,
        //             "sku": "111111",
        //             "productDescription": "test",
        //             "productType": "onetime"
        //         }
        //     ],
        //     "period": null,
        //     "total": 333,
        //     "totalTax": 0,
        //     "balance": 0,
        //     "externalId": "test3",
        //     "description": null,
        //     "number": 114,
        //     "confirmation": {
        //         "send": true,
        //         "email": "test@rebar.com",
        //         "attachment": null
        //     }
        // },
        // {
        //     "id": "test1a13539d5-f167-49ab-adb2-dea4ed6341c252",
        //     "subscriptionId": null,
        //     "created": 1675294552530,
        //     "modified": 1675294552530,
        //     "status": "due",
        //     "lineItems": [
        //         {
        //             "currency": "usd",
        //             "externalId": "test1",
        //             "taxes": [],
        //             "amount": "333",
        //             "quantity": "1",
        //             "productId": "9b8bcbda-f184-468e-aa28-001896991bff",
        //             "productFeatures": "",
        //             "productBillingPlan": "",
        //             "productLogo": "",
        //             "productName": "111111",
        //             "sortOrder": 99,
        //             "sku": "111111",
        //             "productDescription": "test",
        //             "productType": "onetime"
        //         }
        //     ],
        //     "period": null,
        //     "total": 333,
        //     "totalTax": 0,
        //     "balance": 333,
        //     "externalId": "test1",
        //     "description": null,
        //     "number": 113,
        //     "confirmation": {
        //         "send": true,
        //         "email": "test@rebar.com",
        //         "attachment": null
        //     }
        // },
        // {
        //     "id": "test4a13539d5-f167-49ab-adb2-dea4ed6341c255",
        //     "subscriptionId": null,
        //     "created": 1675295875815,
        //     "modified": 1675295890509,
        //     "status": "paid",
        //     "lineItems": [
        //         {
        //             "currency": "usd",
        //             "externalId": "test4",
        //             "taxes": [],
        //             "amount": "333",
        //             "quantity": "1",
        //             "productId": "9b8bcbda-f184-468e-aa28-001896991bff",
        //             "productFeatures": "",
        //             "productBillingPlan": "",
        //             "productLogo": "",
        //             "productName": "111111",
        //             "sortOrder": 99,
        //             "sku": "111111",
        //             "productDescription": "test",
        //             "productType": "onetime"
        //         }
        //     ],
        //     "period": null,
        //     "total": 333,
        //     "totalTax": 0,
        //     "balance": 0,
        //     "externalId": "test4",
        //     "description": null,
        //     "number": 115,
        //     "confirmation": {
        //         "send": true,
        //         "email": "test@rebar.com",
        //         "attachment": null
        //     }
        // }
    ]);

    const [loadingError, setLoadingError] = useState(false);

    const fetchApi = async() => {
        try {
            setLoading(true);
            const invoices = await fetchInvoices();
            console.log(invoices);
            setInvoices(invoices);
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            console.log({ error });
            setLoadingError(true);
            setLoading(false);  
        }
    }

    useEffect(() => {
        (async() => {
            await fetchApi();
        })();
    },[]);    

    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{Strings.billingHistory}</CardTitle>
                </CardContent>
            </CardBody>
            <Divider />
            {loading && 
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Loader small />
                        <Typography children={`${Strings.loading} ${Strings.billingHistory}`} style={{ marginTop: 10 }} />
                    </Flex>
                </>
            }

            {!loading && _.size(invoices) > 0 && 
                <>                    
                    <CardBody paddingTop={2} paddingBottom={2}>
                        <CardContent style={{ width: '100%' }}>
                            <Table colCount={5} rowCount={_.size(invoices)}>
                                <Thead>
                                    <Tr>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.status}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.invoiceId}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.description}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.dueDate}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.total}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.amountDue}</Typography>
                                        </Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {_.map(invoices, i => <Tr key={i.id}>
                                        <Td>
                                            {i.status === 'due' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="warning100" textColor="warning600"children={i.status} />}
                                            {i.status === 'open' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="warning100" textColor="warning600"children={i.status} />}
                                            {i.status === 'late' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="danger100" textColor="danger600" children={i.status} />}
                                            {i.status === 'closed' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="danger100" textColor="danger600" children={i.status} />}
                                            {i.status === 'paid' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="success100" textColor="success600"children={i.status} />}
                                        </Td>
                                        <Td>
                                            <Typography ellipsis>{i.number}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{_.get(i, 'description', 'N/a')}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{moment(i.created).format('MMMM D, YYYY')}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{currencyDisplay(i.total)}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{currencyDisplay(i.balance)}</Typography>
                                        </Td>
                                        <Td>
                                            <Flex direction='row' alignItems='center'>
                                                <IconButton
                                                    icon={<FilePdf />}
                                                    children={Strings.download}
                                                    style={{
                                                        marginRight: 10
                                                    }}
                                                />
                                                {_.get(i, 'status') !== 'paid' && <Button
                                                    size='S'
                                                    variant='danger'
                                                    children={Strings.payNow}
                                                    onClick={() => history.push(_.replace(ScreenNames.PayNow, ':id', i.id), { invoice: i })}
                                                />}
                                            </Flex>
                                        </Td>
                                    </Tr>)}
                                </Tbody>
                            </Table>
                        </CardContent>
                    </CardBody>
                    <Divider />
                    {/* <CardBody style={{ alignItems: 'center', justifyContent: 'center'}}>
                        <Pagination activePage={1} pageCount={26}>
                            <PreviousLink to="#">Go to previous page</PreviousLink>
                            <PageLink to="#" number={1}>
                                Go to page 1
                            </PageLink>
                            <PageLink to="#" number={2}>
                                Go to page 2
                            </PageLink>
                            <Dots>And 23 other links</Dots>
                            <PageLink to="#" number={25}>
                                Go to page 3
                            </PageLink>
                            <PageLink to="#" number={26}>
                                Go to page 26
                            </PageLink>
                            <NextLink to="#">Go to next page</NextLink>
                        </Pagination>
                    </CardBody> */}
                </>                
            }

            {!loading && _.size(invoices) === 0 && !loadingError && 
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`No ${Strings.billingHistory} Available`} style={{ marginTop: 10 }} />
                </Flex>
            }            

            {loadingError &&
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`${Strings.unableToLoad} ${Strings.billingHistory}`} style={{ marginTop: 10 }} />
                    </Flex>
                    <Divider />
                    <CardBody>
                        <Button children={Strings.refresh} fullWidth disabled={loading} onClick={fetchApi} />
                    </CardBody>
                </>
            }
        </Card>
    )
}