import { useState } from 'react';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const app = process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'visualdx' : 'visualdx_dev';

const analyticsSession = Analytics({
  app,
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_KEY]
    })
  ]
});

export default function useGoogleAnalytics() {
    
  const [analytics] = useState(analyticsSession);

  return {
    analytics
  }
}
