import axios from 'axios';
import _ from 'lodash';
import * as jose from 'jose';

const baseURL = _.get(process.env, 'REACT_APP_CLIENT_API_URL');
const cognitoUrl = _.get(process.env, 'REACT_APP_COGNITO_DOMAIN');
const cashboxUsername = _.get(process.env, 'REACT_APP_CASHBOX_USERNAME');
const cashboxPassword = _.get(process.env, 'REACT_APP_CASHBOX_PASSWORD');
const cognitoClientId = _.get(process.env, 'REACT_APP_COGNITO_CLIENT_ID');
const cognitoRedirectUri = _.get(process.env, 'REACT_APP_COGNITO_REDIRECT_URI');
const auth = { username: cashboxUsername, password: cashboxPassword };

export const apiProducts = {
    list: (async ( payload ) => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/products',
            params: {
                ...payload,
            }
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/products/' + id,
        };
        
        return await axios( request );
    }),
}

export const apiVault = {
    authorize: (async () => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            //auth,
            url: '/vault/authorize'
        };
        
        return await axios( request );
    }),
    finalize: (async ( token ) => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            //auth,
            url: '/vault/finalize/' + token,
        };
        
        return await axios( request );
    }),
}

export const apiCognito = {
    userInfo: (async () => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: cognitoUrl,
            url: '/oauth2/userInfo',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
    login: (async () => {
        const url = `${cognitoUrl}/login?client_id=${cognitoClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${cognitoRedirectUri}`;
        window.location.href = url;
    }),
    logout: (async () => {
        const url = `${cognitoUrl}/logout?client_id=${cognitoClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${cognitoRedirectUri}`;
        window.location.href = url;
    }),
    confirmUser: (async () => {}),
    signup: (async () => {
        const url = `${cognitoUrl}/signup?client_id=${cognitoClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${cognitoRedirectUri}`;
        window.location.href = url;
    }),
    confirm: (async () => {}),
    forgotPassword: (async () => {
        const url = `${cognitoUrl}/forgotPassword?client_id=${cognitoClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${cognitoRedirectUri}`;
        window.location.href = url;
    }),
    confirmforgotPassword: (async () => {}),
    resendcode: (async () => {}),
}

export const apiPaymentMethods = {
    create: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/payment-methods',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: payload
        };
        
        return await axios( request );
    }),
    update: (async ( id, payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            url: '/payment-methods/' + id,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: payload
        };
        
        return await axios( request );
    }),
    list: (async () => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/payment-methods',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/payment-methods/' + id,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
}

export const apiAccounts = {
    create: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/customers',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
    update: (async ( id, payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            url: '/customers/' + id,
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/customers/' + id,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        
        return await axios( request );
    }),
}

export const apiAddDiscount = {
    update: (async ( id, payload, dryrun ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: `/subscriptions/${id}/add_discount_ticket`,
            data: payload,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: {
                dryrun
            }
        };
        
        return await axios( request );
    })
}

export const apiSubscriptions = {
    create: (async ( payload, dryrun = false ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/subscriptions',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: payload,
        };
        
        return await axios( request );
    }),
    modify: (async ( id, payload) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            url: '/subscriptions/' + id,
            data: payload,
            headers: { Authorization: `Bearer ${token}` }
        };
        
        return await axios( request );
    }),
    update: (async ( id, payload) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            url: '/subscriptions/' + id,
            data: payload,
            headers: { Authorization: `Bearer ${token}` }
        };
        
        return await axios( request );
    }),
    list: (async () => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/subscriptions',
            headers: { Authorization: `Bearer ${token}` },
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            headers: { Authorization: `Bearer ${token}` },
            url: '/subscriptions/' + id,
        };
        
        return await axios( request );
    }),
    cancel: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/subscriptions/' + id + '/cancel',
            headers: { Authorization: `Bearer ${token}` }
        };
        
        return await axios( request );
    }),
}

export const apiCharges = {
    create: (async ( payload, dryrun = false ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/charges',
            data: payload,
        };

        if(dryrun) request.params = { dryrun: true }
        
        return await axios( request );
    })
}

export const apiInvoices = {       
    list: (async () => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/invoices',
            headers: { Authorization: `Bearer ${token}` },
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/invoices/' + id,
            headers: { Authorization: `Bearer ${token}` },
        };
        
        return await axios( request );
    }),
    pay: (async ( id, payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/invoices/' + id + '/pay',
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        };
        
        return await axios( request );
    }),
    otp: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/invoices/otp',
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        };
        
        return await axios( request );
    }),
}

export const apiTransactions = {       
    list: (async () => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            url: '/transactions',
            headers: { Authorization: `Bearer ${token}` },
        };
        
        return await axios( request );
    })
}

export const apiAuth = {
    verify: (async ( jwt ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/auth/verify',
            data: {
                jwt
            }
        };
        
        return await axios( request );
    }),
    verifyMe: (async ( jwt ) => {
        const fetchedPublicKey = await fetchPublicKey();
        console.log('jwk', fetchedPublicKey.data.keys[0]);
        const alg = process.env.REACT_APP_JWT_ALG;
        console.log('alg', alg);
        // delete fetchedPublicKey.data.keys[0].kid;
        const jwk = fetchedPublicKey.data.keys[0];
        const publicKey = await jose.importJWK(jwk, alg);
        console.log('publicKey', publicKey);
        const { payload } = await jose.jwtVerify( jwt, publicKey );
        console.log('payload', payload);
        return payload;
        
    }),
}

export const apiGoogle = {    
    placeAutocomplete: (async({ types = 'geocode', input }) => {
        const request = {
            method: 'get',
            baseURL: `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
            params: {
                key: process.env.REACT_APP_GOOGLE_API_KEY,
                types,
                input
            }
        };

        return axios(request);
    })
}

export const apiCampaigns = {
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/campaigns/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };        
        return await axios( request );
    }),
}

export const fetchPublicKey = async () => {

    try {

        const request = {
            method: 'get',
            auth,
            baseURL: process.env.REACT_APP_JWT_KEY_URL
        };

        return await axios( request );

    } catch (error) {
        console.log('fetchPublicKey Error: ', error);
        return _.get( error, 'response', false );
    }
};

export const certCaptureGetToken = {
    get: (async ( customer_number ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/certcapture/get-token',
            data: {
                customer_number
            },
        };
        return await axios( request );
    }),
}

export const certCaptureUpsertCustomer = {
    put: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/certcapture/customer',
            data: payload,
        };        
        return await axios( request );
    }),
}

export const logErrorService = {
    create: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            url: '/log/error',
            data: payload,
        };        
        return await axios( request );
    }),
}