import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { styleSettings } from "../config";
import { Button } from '@strapi/design-system/Button';
import { Box } from '@strapi/design-system/Box';
import { Divider } from '@strapi/design-system/Divider';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Typography } from '@strapi/design-system/Typography';
import { Flex } from '@strapi/design-system/Flex';
import { Checkbox } from '@strapi/design-system/Checkbox';
import { Alert } from '@strapi/design-system/Alert';
import { Loader } from '@strapi/design-system/Loader';
import { Link } from '@strapi/design-system/Link';
import { EmptyStateLayout } from '@strapi/design-system/EmptyStateLayout';
import ArrowLeft from '@strapi/icons/ArrowLeft';
import EmptyDocuments from '@strapi/icons/EmptyDocuments';
import { 
    Card,
    CardBody,
    CardContent
} from '@strapi/design-system/Card';
import { Switch } from '@strapi/design-system/Switch';
import PromoCodeBox from "./PromoCodeBox";
import { currencyDisplay } from "../utils/helpers";
import Strings from "../constants/Strings";
import useDeviceTypes from '../hooks/useDeviceTypes';

import useUser from "../hooks/useUser";
import useAPI from '../hooks/useAPI';
import ScreenNames from "../constants/ScreenNames";

export default function PackagingByFeatures(props) {

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const campaign = queryParams.get('campaign');
    const category = queryParams.get('category');

    const { setCart, cart, user, getAffiliate, getCampaign, product, setCampaign, getAffiliateCampaign, getProduct, entitlement, getEntitlement } = useUser();

    const { isWidth, isMobileTablet } = useDeviceTypes();
    const { fetchProductPackages, fetchSubscription, purchaseSubscription, fetchCampaign, fetchPaymentMethods, fetchSubscriptions } = useAPI();
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: '' });
    const [subscriptions, setSubscriptions] = useState([]);
    const [switchSelected, setSwitchSelected] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [subscription, setSubscription] = useState({});

    const SelectButton = (props) => <Button size="L" loading={apiLoading || submitting} children={(apiLoading || submitting) ? Strings.loading : Strings.continue} onClick={submit} disabled={selectedPackage?.id ? false : true} {...props} />;

    const updateSelectedAddOns = (value) => {
        const found = _.find(selectedAddOns, value);
        if(!found) setSelectedAddOns([...selectedAddOns, value]);
        else setSelectedAddOns(() => {
            return _.filter(selectedAddOns, v => v !== found);
        });
    }

    const [submitting, setSubmitting] = useState(false);
    const submit = async() => {
        setSubmitting(true);
        if(!selectedPackage) {
            return false;
        }

        const body = {
            package: selectedPackage,
            addOns: selectedAddOns,
            frequency: switchSelected ? 'year' : 'month'
        }

        //If product/subscription dryRun does not have a campaign_id, then remove the campaign_code from storage
        if(!selectedPackage?.dry_response?.items?.data[0]?.campaign_id) setCampaign('');

        setCart([body]);

        // If campaign code is a coupon and price is 0, skip payment method
        if((_.toLower(selectedPackage?.campaign_response?.type) === 'coupon') && (selectedPackage.price === 0)) { 
            return history.push(ScreenNames.PurchaseConfirmation);
        }

        try {
            // Check for payment methods
            const paymentMethods = await fetchPaymentMethods();

            if(_.size(paymentMethods) > 0) history.push(ScreenNames.PurchaseConfirmation)
            else history.push(ScreenNames.Payment);
        } catch (error) {
            console.log({ error });
        } finally {
            setSubmitting(false);
        }

    };

    const convertTermText = ({ quantity, type }) => {
        if(quantity === 1) {
            return _.toLower(`per ${type}`);
        } else {
            return _.toLower(`every ${quantity} ${type}s`);
        }
    };

    const renderGridItem = (item) => (
        <GridItem col={4} s={12} xs={12} key={item.id}>
            <Card 
                style={{ cursor: 'pointer', minHeight: 300 }} 
                onClick={() => setSelectedPackage(item)}
                borderColor={selectedPackage?.id === item.id ? styleSettings.cards.selectedBorderColor : styleSettings.cards.borderColor}
                shadow={selectedPackage?.id && styleSettings.cards.shadow }>
                <CardBody style={{ justifyContent: 'center'}}>
                    <CardContent style={{ width: '100%' }}>
                        <Box
                            hasRadius 
                            padding={3}
                            style={{ width: '100%'}}
                            children={
                                <Flex direction="column">
                                    <Typography style={{ textAlign: 'center', wordBreak: 'keep-all'}} variant="epsilon">{item.description}</Typography>
                                    {
                                        <>
                                            <Typography variant="alpha" style={{ marginTop: 10, wordBreak: 'keep-all' }}>{`${currencyDisplay(item.prices[0].amount)}`}</Typography>
                                            <Typography textColor={'neutral300'} variant="sigma">{item.type}</Typography>
                                        </>
                                    }
                                    
                                    <Divider style={{ width: '100%', marginTop: 30, marginBottom: -10 }} />
                                    
                                    <Flex direction="column" alignItems={'left'} style={{ wordBreak: 'keep-all', width: '100%'}}>
                                        <Typography variant="beta" textColor="primary600" style={{ marginTop: 35, }}>{Strings.packagingFeatures}</Typography>
                                        {_.map(item.metadata, (meta, index) => <Flex key={index} direction="column" alignItems="left" style={{ wordBreak: 'keep-all'}}>
                                            <Typography variant="omega" style={{ marginTop: 20 }}>{products.product}</Typography>
                                            <Typography variant="omega" textColor={'neutral600'} style={{ textAlign: 'left'}}>{meta.value}</Typography>
                                        </Flex>)}
                                    </Flex>
                                </Flex>
                            }
                        />
                    </CardContent>
                </CardBody>
            </Card>  
        </GridItem>
    );

    const getAffiliateCode = () => getAffiliate() ? getAffiliate() : _.get(process.env, 'REACT_APP_AFFILIATE');
    
    const getCampaignCode = () => {
        const affiliateCampaignCode = getAffiliateCampaign() ? getAffiliateCampaign() : null;

        if(!_.isNull(affiliateCampaignCode)) return affiliateCampaignCode;

        const campaignCode = getCampaign() ? getCampaign() : null;

        if(!_.isNull(campaignCode)) return campaignCode;

        return '';
    };

    const getCampaignId = (dryRunResponse) => _.get(dryRunResponse, 'items.data.0.campaign_id');

    const hasCampaign = (dryRunResponse) => _.isUndefined(getCampaignId(dryRunResponse)) === false;

    const getDryRunPromise = async (fetchedPackage) => {
        const handleDryRunError = (dryRunError, output) => {
            console.log('Error with dry run while fetching price.', dryRunError );
            
            const errorMessage = _.get(dryRunError, 'response.data.errorMessage', '');
    
            switch (dryRunError.response.status) {
                case 400:
                    if(errorMessage.includes('Failed to redeem code')) {
                        setShowAlert({ variant: 'danger', active: true, body: `Promo code is invalid or expired.  Please try again with another code.` });                    
                    }
                    if(errorMessage.includes('Subscription create failed: Campaign code')) {
                        setShowAlert({ variant: 'danger', active: true, body: `Campaign code is invalid or expired.  Please try again with another code.` });  
                        setCampaign('');
                    }
                    if(errorMessage.includes('Subscription create failed: Campaign redemption error')){
                        console.log('Ignoring error: for product display' );
                        return Promise.resolve(output);
                    }
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: dryRunError.response.data.data.message });
                    break;
            }
    
            return Promise.resolve(output);
        };
    
        const handleFetchCampaignError = (fetchCampaignError, output) => {
            console.log('Error with dry run while fetching campaign details.', fetchCampaignError );
    
            return Promise.resolve(output);
        };

        const affiliate = getAffiliateCode();
        const campaign_code = getCampaignCode();
        const account = { object: 'Account', id: _.get(user, 'id')};
        const product = { object: 'Product', vid: _.get(fetchedPackage, 'response.vid')};
        const subscriptionItem = { object: 'SubscriptionItem', id: uuidv4(), product, campaign_code };
        const billing_plan = { object: 'BillingPlan', id: _.get(fetchedPackage, 'response.default_billing_plan.id')};
        
        const body = { object: 'Subscription', currency: 'USD', id: uuidv4(), account, affiliate, billing_plan, items: [subscriptionItem]};
            
        const dryRunResponse = await purchaseSubscription(body, true).catch((e) => handleDryRunError(e, fetchedPackage));
        
        const newPackage = { 
            ...fetchedPackage,
            dry_response: dryRunResponse,
            campaign_code: getCampaignCode(), 
            price: _.get(dryRunResponse, 'price', _.get(dryRunResponse, 'next_billing.amount'))
        };

        if(hasCampaign(dryRunResponse)){ 
            const fetchCampaignResponse = await fetchCampaign(getCampaignId(dryRunResponse)).catch((e) => handleFetchCampaignError(e, newPackage));

            _.set(newPackage, 'campaign_response', fetchCampaignResponse);

            console.log({ dryRunResult: newPackage, hasCampaign: true });

            return newPackage;
        }

        console.log({ dryRunResult: newPackage, hasCampaign: false });
        
        return newPackage;
    };

    const [disabledSubscription, setDisabledSubscription] = useState('');

    // Move selected product to the top of the list
    useEffect(() => {
        // if(selectedPackage?.id && !_.isEmpty(products)) {
        //     let found = _.find(products, i => i.id === selectedPackage.id);
        //     let filtered = _.filter(products, i => i.id !== selectedPackage.id);
        //     setProducts([found, ...filtered]);
        // }
    },[selectedPackage]);

    const getProducts = async() => {
        setLoading(true);
        try {
            const products = await fetchProductPackages();
            console.log({ products });
            // Get status active products
            const activeProducts = _.filter(products, i => i.status === 'active');
            setProducts(activeProducts);
        } catch (error) {
            console.log({ method: 'getProducts', error });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async() => {
            if (campaign !== 'null' && campaign !== null && campaign !== 'undefined' && campaign) setCampaign(campaign);
            // await fetchApi();
            const subscriptions = await fetchSubscriptions();
            setSubscriptions(subscriptions);
            await getProducts();
        })();
    }, []);

    useEffect(() => {
        // Find the active subscription
        const activeSubscription = _.find(subscriptions, i => i.status === 'active');
        if(activeSubscription) {
            // If active subscription, highlight the package
            console.log({ activeSubscription });
            setSelectedPackage(_.get(activeSubscription, 'products.0'));
        }
    },[subscriptions]);

    useEffect(() => {
        return history.listen((location) => {            
            const updatedQueryParams = new URLSearchParams(location.search);            
            const updatedCampaign = updatedQueryParams.get('campaign');
            if(updatedCampaign) {
                //console.log(`You changed the campaign code to: `, updatedCampaign);
                setCampaign(updatedCampaign);
            }
            // fetchApi();
            getProducts();
        });
    },[history]);

    useEffect(() => {
        setSelectedAddOns([]);
    },[selectedPackage]);

    const disableSwitch = (products) => {
        if (_.size(products) === 0) return true;
        if (_.size(products) > 0) return false;
    }

    const hideSwitch = (products) => {
        if (_.some(products, i => i.term === 'year') && _.some(products, i => i.term === 'month')) return false;
        else return true;
    }

    return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Flex style={{ width: isMobileTablet ? '100%' : '80%' }} alignItems='center' justifyContent='center'>
                {!loading ? <Flex direction="column" style={{ width: '100%', ...props.style }}>
                    {_.size(subscriptions) > 0 && <Flex paddingBottom={5} alignItems={'flex-start'} style={{ width: '100%' }}>
                        <Link to={ScreenNames.Billing} startIcon={<ArrowLeft />}>
                            Manage Subscription
                        </Link>
                    </Flex>}
                    
                    <Flex style={{ width: '100%'}} alignItems='center' justifyContent='center'>
                        {_.size(products) > 0 && <Grid 
                            gap={5} 
                            //gridCols={3}
                            direction={isMobileTablet ? 'column' : 'row'} 
                            style={{ width: '100%' }} >
                                {/* <GridItem col={3} s={12}>
                                    <PromoCodeBox to={ScreenNames.Subscriptions} />
                                </GridItem> */}
                                <GridItem col={12} s={12}>
                                    <Grid gap={5}>
                                    {_.map(products, item =>
                                        <>
                                            {/* {!switchSelected && item.term !== 'year' && renderGridItem(item) }
                                            {!switchSelected && item.term !== 'year' && renderGridItem(item) } */}
                                            {renderGridItem(item)}
                                        </>
                                    )}
                                    </Grid>
                                </GridItem>                        
                        </Grid>}
                        
                        {_.size(products) === 0 && <Box padding={5} style={{ width: isWidth }}>
                            <EmptyStateLayout style={{ width: '100%'}} icon={<EmptyDocuments width="10rem" />} content={Strings.noDataAvailable} />
                        </Box>}
                    </Flex>
                    {_.size(products) > 0 && <Card padding={4} style={{ width: '100%', marginTop: 20 }} borderColor={styleSettings.cards.borderColor} shadow={styleSettings.cards.shadow}>
                        <CardContent>
                            <CardBody style={{ alignItems: 'center' }}>
                                <Flex justifyContent='space-between' width='100%' direction={isMobileTablet ? 'column' : 'row'} >
                                    {hideSwitch(products) ? <Box />  : <Switch label="Monthly or Yearly" disabled={disableSwitch(products)} selected={switchSelected} onLabel={Strings.annualSubscription} offLabel={Strings.monthlySubscription} onChange={() => setSwitchSelected(s => !s)} visibleLabels /> }                   
                                    <SelectButton style={{ marginTop: isMobileTablet && 15 }} />
                                </Flex>
                            </CardBody>
                        </CardContent>
                    </Card>}
                </Flex> :
                <Loader style={{ marginTop: 300 }}>{'Loading Content'}</Loader>}
                
            </Flex>
        </>
    )
}